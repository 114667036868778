<template>
  <div class="home">
    <v-dialog v-model="dialogLoad" width="200">
      <v-card>
        <v-progress-linear color="#006794" indeterminate rounded height="6"></v-progress-linear>
      </v-card>
    </v-dialog>
    <div class="row">
      <div class="col-md-12" style="margin-top:70px !important; height:70px !important; padding:30px 0 0px 10px; position:fixed; z-index: 1; background:#fff; border-bottom: solid 1px #ccc; padding-left:40px">
        <span style="color:#777; font-size:18px;padding:5px;">Utme</span>/<span style="padding:5px; color:#555">Postutme</span>
      </div>
    </div>
  
    <div style="background: #f5f5f5; margin-top:180px" class=" container-fluid"></div>
    <v-row style="margin:0 !important; padding:0 !important">
      <v-card class="pt-8">
        <v-col cols="12" style="">
  
          <div class="row">
            <div class="col-12">
              <div class="row">
                <div class="col-md-2 py-0">
                  <strong>Email</strong>
                  <v-text-field :clearable="clearable" v-model="filter.email" class="mt-3" label="Enter Email" outlined dense></v-text-field>
                </div>
                <div class="col-md-2 py-0">
                  <strong>Phone</strong>
                  <v-text-field :clearable="clearable" v-model="filter.phone" class="mt-3" label="Enter Phone" outlined dense></v-text-field>
                </div>
                <div class="col-md-2 py-0">
                  <strong>Jamb Reg Number</strong>
                  <v-text-field :clearable="clearable" v-model="filter.j_reg" class="mt-3" label="Enter Jamb reg" outlined dense></v-text-field>
                </div>
                <div class="col-md-2 py-0">
                  <strong>Status</strong>
                  <v-select :clearable="clearable" :items="status" v-model="filter.stage" class="mt-3" label="Select Status" outlined dense></v-select>
                </div>
                <div class="col-md-2 py-0">
                  <strong>Admission Session</strong>
                  <v-select :clearable="clearable" :items="sessions" v-model="filter.admission_session" class="mt-3" label="Select Admission Session" outlined dense></v-select>
                </div>
                <div class="col-md-2 py-0">
                  <strong>Type</strong>
                  <v-select :clearable="clearable" :items="types" v-model="filter.type" class="mt-3" label="Select Type" outlined dense></v-select>
                </div>
  
                <div class="col-2 py-0">
  
                  <div style="margin-top: 2.2rem">
                    <v-btn @click="clear" class="mr-1 btn-clear">clear</v-btn>
  
                    <v-btn @click="submit" :loading="loading"
      :disabled="loading" class="btn-search" color="#006794">Search</v-btn>
                      </div>
  
                </div>
  
              </div>
            </div>
            <div class="col-md-8 "> <strong>Number of result {{datas.length }}</strong></div>
            <div class="col-md-4 mb-8">
              <v-text-field v-model="search" append-icon="mdi-magnify" label="Search For Invoice" single-line hide-details></v-text-field>
            </div>
          </div>

          <v-btn @click=" csvExport(csvData3)" class="mr-1 btn-clear">Download Search Result</v-btn>
          <v-data-table :search="search" :headers="headers" :items="datas">
            <template v-slot:item.actions="{ item }">
                    <v-menu offset-y>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn color="" text v-bind="attrs" v-on="on">
                                <v-icon>mdi-dots-vertical</v-icon>
                            </v-btn>
                        </template>
                        <v-list>
                            <v-list-item @click="view(item)">
                                <v-list-item-title><v-icon small class="mr-2">
                                    mdi-eye
                                </v-icon>View</v-list-item-title>
                            </v-list-item>
                            </v-list>
                        </v-menu>
                        </template>
          </v-data-table>
        </v-col>
      </v-card>
    </v-row>

    <v-dialog max-width="500" v-model="show" persistent>
          <v-card>
            <v-card-title style="color:#666; font-size:18px">
              Select Setup Type
              <v-spacer></v-spacer>
              <v-icon @click="show=false">mdi-close-circle</v-icon>

            </v-card-title>
            <v-divider style="margin-top:0px"></v-divider>

            <v-card-text>
              <p><strong>Name: </strong>{{ item.name }}</p>
              <p><strong>Reg Number: </strong>{{ item.j_reg }}</p>
              <p><strong>Email: </strong>{{ item.email }}</p>
              <p><strong>Phone: </strong>{{ item.phone }}</p>
              <p><strong>Type: </strong>{{ item.type }}</p>
              <p><strong>Status: </strong>{{ item.status }}</p>
              <p> <strong>Date Linked: </strong>{{ item.el.linkDate.split('T')[0] }}</p>
              <p  v-if="item.status == 'Complete'"><strong>Date Finished: </strong>{{ item.el.datefinished.split('T')[0] }}</p>
              <v-btn v-if="item.status == 'Incomplete'" color="#c0e7fe" class="col-md-5 ma-auto" @click="delink">Delink</v-btn>
              <!-- <v-btn color="#c0e7fe" class="col-md-5 ma-auto" @click="delink">Delink</v-btn> -->
            </v-card-text>
            <v-card-actions>

              <v-spacer></v-spacer>

              <!-- <v-btn color="#c0e7fe" class="col-md-5 ma-auto" @click="proceed">Proceed</v-btn> -->

            </v-card-actions>
          </v-card>
        </v-dialog>
  
    
  </div>
  </template>
  
  <script>
  import axios from 'axios';
import Swal from 'sweetalert2';
  export default {
    data() {
      return {
        loading: false,
        rawdata:[],
        search: '',
        show: false,
        dialogLoad: false,
        departmentDisabled: true,
        status: [{text: 'Completed', value: 2}, {text: 'Incomplete', value: 1}],
        clearable: true,
        filter: {
         email: '',
         phone: '',
         stage: '',
         j_reg: '',
         type: '',
         admission_session: ''
        },
        types: [{text: 'UTME', value: 1}, {text: 'Direct Entry', value: 2}],
        sessions: ['2022_2023','2023_2024'],
        headers: [
          {
            text: 'Name',
            value: 'name'
          },
          {
            text: 'Jamb Reg Number',
            value: 'j_reg'
          },
          {
            text: 'Email',
            value: 'email'
          },
          {
            text: 'Phone',
            value: 'phone'
          },
          {
            text: 'Type',
            value: 'type'
          },
          {
            text: 'Status',
            value: 'status'
          },
          {
            text: '',
            value: 'actions'
          },
        ],
        item: {el: {linkDate: '', datefinished: ''}},
        name: "",
        datas: []
      }
    },
  
    computed:{

                csvData3() {
                  var keys = ['grade', 'subjectName']
                return this.rawdata.map(item => ({
                    Regnumber:item.j_reg,
                    Names:item.jambregId.surname +' '+ item.jambregId.name,
                    Phone:item.phone,
                    email:item.email,
                    Type: item.jambregId.type==1?'UTME':'DE',
                    Jambscore : item.jambregId.total,
                    State:item.jambregId.state,
                    Result1: (item.olevelResults != undefined&&item.olevelResults.result1)?this.formatResult(item.olevelResults.result1.grades):'',
                    Result2: (item.olevelResults != undefined && item.olevelResults.result2 != undefined)?this.formatResult(item.olevelResults.result2.grades):''
                }));
            },
        },
  
    beforeMount() {
      
    },
    methods: {

      formatResult(yyx){
  //      var result = Object.assign({}, ...yyx.map(o => ({[o.subjectName]: o.grade})))
    
  //   console.log(result.join('-'))
  // var res = JSON.stringify(result);
  // var remove1 = res.replace('"', '')
  // var remove = remove1.replace(':', '=')
  // var place1=  remove.replace('{', '(')
  // var place2 = place1.replace('}',')')
  // var place3 = place2.replace(',','_')
  // return place3


  // return yyx.reduce(function (a, b) {
  //       return (a.subjectName||a) + "_" + b.subjectName}
  //   )
console.log(yyx);
var res = []
yyx.forEach(element => {
  console.log('key',element.grade);  
var data = element.subjectName + ':' + element.grade;
res.push(data)      
});
console.log(res);
// var res1 = JSON.stringify(res).replace(",","_")
var res1 = res.map(o => o).join('_')
return res1
      },

      clear() {
        this.filter = {
         email: '',
         phone: '',
         status: '',
         j_reg: ''
        };
      },

      view(item){
        console.log(item);
        this.item = item;
        this.show = true;
      },
      csvExport(arrData) {
                // if (this.filteredItemsthree.length == 0) {
                //     Swal.fire({
                //         icon: "info",
                //         text: "Table Is Empty",
                //         width: 400,
                //     });
                // } else {
                    let csvContent = "data:text/csv;charset=utf-8,";
                    csvContent += [
                            Object.keys(arrData[0]),
                            ...arrData.map(item => Object.values(item))
                        ]
                        // .trim()
                        .join("\n")
                        .replace(/(^\[)|(\]$)/gm, "");
    
                    const data = encodeURI(csvContent);
                    const link = document.createElement("a");
                    link.setAttribute("href", data);
                    link.setAttribute("download", "postumeapplications.csv");
                    link.click();
               // }
            },

      async delink(){
        console.log('Delinked');
        console.log('Checking', this.item, this.data)

        try {
          const res = await axios.post(`${this.$appUrl}/delink-utme`, {id: this.item.id, adYear: this.filter.admission_session, delinkedBy: 'skolarsuite' });

          if(res.data.code === 's200'){
            // this.data.splice(this.data.indexOf(this.item), 1);
            this.datas = this.datas.filter(el => el.id !== this.item.id);
            this.show = false;
            Swal.fire({icon: 'success', text: 'Successfuly Delinked', title: 'Succesful'})
          }else{
            Swal.fire({icon: 'error', text: res.data.message, title: 'Oops..'})
          }


        } catch (error) {
          console.log(error);
        }
      },

      async submit(){
        try {
          this.loading = true;
          const res = await axios.post(`${this.$appUrl}/fetch-post-reg`, this.filter);
console.log('Hello',res);
          if(res.data.code == 's200'){
const data = [];
this.rawdata = res.data.message;
            res.data.message.forEach(el => {
              data.push({
                name: `${el.jambregId.surname} ${ el.jambregId.name} ${ el.jambregId.othernames}`,
j_reg: el.j_reg,
email: el.email,
phone: el.phone,
id: el._id,
el,
type: el.jambregId.type == '1' ? 'UTME' : 'Direct Entry',
status: el.stage == 1 ? 'Incomplete' : 'Completed'
              })
            });

            this.datas = data;
          }
          this.loading = false;
        } catch (error) {
          this.loading = false;
          console.log(error);
        }
      },  
    }
  }
  </script>
  
  <style>
  .btn-search {
    color: #fff !important;
    padding: 1.1rem !important;
    font-size: 10px !important;
  
  }
  
  .btn-clear {
    padding: 1.1rem !important;
    font-size: 10px !important;
  }
  </style>
  