<template>
<div class="home">
    <div class="row">
        <div class="col-md-12" style="margin-top:70px !important; height:70px !important; padding:30px 0 0px 10px; position:fixed; z-index: 1; background:#fff;  padding-left:40px">
            <span style="color:#777; font-size:18px;padding:10px;">Assign Menu </span>
        </div>
    </div>
         <div style="background: #f5f5f5; margin-top:150px; " class=" container-fluid row">
         <div style="background-color:#F7DBCC; height: 350px;" class="col-md-3">
<div id="avatar" style="margin:auto"></div>
<!-- {{staffData}} -->
<div class="col-md-12" style="margin-top:-20px; text-align:center; font-weight:600; color:#555"><i class="fa fa-book"></i>
{{staffData.prefix}} {{staffData.surname}} {{staffData.name}}
</div>
<div class="col-md-12" style="margin-top:-30px; text-align:center; font-weight:; color:#555"><i class="fa fa-book"></i>
{{staffData.cadre}} 
</div>
<div class="col-md-12" style="margin-top:-20px; text-align:center; font-weight:600; color:#555"><i class="fa fa-book"></i>
{{staffData.email}}
</div>
<div class="col-md-3" style="background-color:#4BB543; margin:auto; border-radius: 50px;padding:3px !important; text-align: center; font-size:14px; color:#fff; font-weight: 600; margin-top:-10px">{{staffData.status}}
<!-- <div class="dotactive" style=""></div> -->

</div>

         </div>
         <div class="col-md-9">
<div style="background-color:#fff; heigh:auto; min-height: 320px;" class="col-md-12">
<div class="col-lg-12" v-if="staffData.status == 'Active'">
                    <!-- <pre>{{resData.length}}</pre> -->
<span style="color:#777; font-size:18px;padding:10px;">Assigning menu to - {{staffData.prefix}} {{staffData.surname}} {{staffData.name}}</span>
                    <div class="mt-1 ml-0 pl-0 container wrapper">
                        <div class="pl-4 ">
                            <v-alert color="primary" border="left" dark prominent dismissible>
                        <p><strong>To assign menu(s) to {{staffData.prefix}} {{staffData.surname}} {{staffData.name}} </strong> <br></p>
                         <p>Menus are assigned based on programs. Click on the program tab to see the menu available for each program.</p>
                        <p>Click on the checkbox by the menu you intend to assign </p>
                        <p>If the menu is not a service menu, you will have to click on the plus icon under assign department to assign the user department(s) to administer. Without assigning a department, the menu will list when the user logs in but the user will not be able to operate the menu.</p>
                        <p>Click on any of the save buttons to save your operation</p>
                    </v-alert>
                            <v-tabs background-color="white">
                                <v-tab v-for="(program,i) in programNames" :key="i">{{program}}</v-tab>
            
                                <v-tab-item v-if="Program1">
                                <div style="max-height:600px;overflow:scroll">
                                    <div v-for="(menu, i) in  Program1.menus" :key="i" class="my-7">
                                        <h6 style="color:#555">{{menu.menu_name}}</h6>
                                        <v-data-table v-model="selected1" :single-select="false" :headers="headers"
                                            :items="menu.sub_menu" item-key="id" show-select class="elevation-1">

                                            <template v-slot:item.action="{ item }">
                                            <div @click="assignDepartment(item, Program1, selected1)" style="cursor:pointer">
                                                <v-icon class="mr-2" >
                                                    mdi-plus-circle 
                                                </v-icon> <span style="margin-left:-15px"></span>
                                                </div>
                                            </template>
                                            <template v-slot:item.department_assigned="{item}">
                                                <span v-if="item.department_assigned.length == 0">No Departments</span>
                                                <span>{{ `${item.department_assigned.map(el => el.text) }`}}</span>
                                            </template>
                                          
      <!-- <template v-slot:footer>

</template> -->
                                        </v-data-table>
                                    </div>
                                    <!-- <div class="py-5">
                                        <button class="save_button"
                                            @click="submit(Program1.program, selected1)">Save</button>
                                    </div> -->
                                    </div>
                                    <div class="col-md-12 mt-2 mb-0">
                                    <v-btn
           color="blue darken-1"
          dark
          class="ma-2"
          @click="submit(Program1.program, selected1)">
            Save Menu Asigned
          </v-btn>
        </div>
                                </v-tab-item>

                                <v-tab-item v-if="Program2">
                                    <div v-for="(menu, i) in  Program2.menus" :key="i" class="my-7">
                                        <h4>{{menu.menu_name}}</h4>
                                        <v-data-table v-model="selected2" :single-select="false" :headers="headers"
                                            :items="menu.sub_menu" item-key="id" show-select class="elevation-1">

                                            <template v-slot:item.action="{ item }">
                                                <v-icon class="mr-2" @click="assignDepartment(item, Program2, selected2)">
                                                    mdi-spellcheck
                                                </v-icon>
                                            </template>
                                            <template v-slot:item.department_assigned="{item}">
                                                <span v-if="item.department_assigned.length == 0">No Departments</span>
                                                <span>{{ ` ${item.department_assigned.map(el => el.text) }`}}</span>
                                            </template>
                                        </v-data-table>
                                    </div>
                                    <div class="py-5">
                                        <button class="save_button"
                                            @click="submit(Program2.program, selected2)">Save</button>
                                    </div>
                                </v-tab-item>

                                <v-tab-item v-if="Program3">
                                    <div v-for="(menu, i) in  Program3.menus" :key="i" class="my-7">
                                        <h4>{{menu.menu_name}}</h4>
                                        <v-data-table v-model="selected3" :single-select="false" :headers="headers"
                                            :items="menu.sub_menu" item-key="id" show-select class="elevation-1">

                                            <template v-slot:item.action="{ item }">
                                                <v-icon class="mr-2" @click="assignDepartment(item, Program3,selected3)">
                                                    mdi-spellcheck
                                                </v-icon>
                                            </template>
                                            <template v-slot:item.department_assigned="{item}">
                                                <span v-if="item.department_assigned.length == 0">No Departments</span>
                                                <span>{{ `${item.department_assigned.map(el => el.text) }`}}</span>
                                            </template>
                                        </v-data-table>
                                    </div>
                                    <div class="py-5">
                                        <button class="save_button"
                                            @click="submit(Program3.program, selected3)">Save</button>
                                    </div>
                                </v-tab-item>

                                <v-tab-item v-if="Program4">
                                    <div v-for="(menu, i) in  Program4.menus" :key="i" class="my-7">
                                        <h4>{{menu.menu_name}}</h4>
                                        <v-data-table v-model="selected4" :single-select="false" :headers="headers"
                                            :items="menu.sub_menu" item-key="id" show-select class="elevation-1">

                                            <template v-slot:item.action="{ item }">
                                                <v-icon class="mr-2" @click="assignDepartment(item, Program4, selected4)">
                                                    mdi-spellcheck
                                                </v-icon>
                                            </template>
                                            <template v-slot:item.department_assigned="{item}">
                                                <span v-if="item.department_assigned.length == 0">No Departments</span>
                                                <span>{{ `${item.department_assigned.map(el => el.text) }`}}</span>
                                            </template>
                                        </v-data-table>
                                    </div>
                                    <div class="py-5">
                                        <button class="save_button"
                                            @click="submit(Program4.program, selected4)">Save</button>
                                    </div>
                                </v-tab-item>

                                <v-tab-item v-if="Program5">
                                    <div v-for="(menu, i) in  Program5.menus" :key="i" class="my-7">
                                        <h4>{{menu.menu_name}}</h4>
                                        <v-data-table v-model="selected5" :single-select="false" :headers="headers"
                                            :items="menu.sub_menu" item-key="id" show-select class="elevation-1">

                                            <template v-slot:item.action="{ item }">
                                                <v-icon class="mr-2" @click="assignDepartment(item, Program5, selected5)">
                                                    mdi-spellcheck
                                                </v-icon>
                                            </template>
                                            <template v-slot:item.department_assigned="{item}">
                                                <span v-if="item.department_assigned.length == 0">No Departments</span>
                                                <span>{{ `${item.department_assigned.map(el => el.text) }`}}</span>
                                            </template>
                                        </v-data-table>
                                    </div>
                                    <div class="py-5">
                                        <button class="save_button"
                                            @click="submit(Program5.program, selected5)">Save</button>
                                    </div>
                                </v-tab-item>

                                <v-tab-item v-if="Program6">
                                    <div v-for="(menu, i) in  Program6.menus" :key="i" class="my-7">
                                        <h4>{{menu.menu_name}}</h4>
                                        <v-data-table v-model="selected6" :single-select="false" :headers="headers"
                                            :items="menu.sub_menu" item-key="id" show-select class="elevation-1">

                                            <template v-slot:item.action="{ item }">
                                                <v-icon class="mr-2" @click="assignDepartment(item, Program6, selected6)">
                                                    mdi-spellcheck
                                                </v-icon>
                                            </template>
                                            <template v-slot:item.department_assigned="{item}">
                                                <span v-if="item.department_assigned.length == 0">No Departments</span>
                                                <span>{{ `${item.department_assigned.map(el => el.text) }`}}</span>
                                            </template>
                                        </v-data-table>
                                    </div>
                                    <div class="py-5">
                                        <button class="save_button"
                                            @click="submit(Program6.program, selected6)">Save</button>
                                    </div>
                                </v-tab-item>

                                <v-tab-item v-if="Program7">
                                    <div v-for="(menu, i) in  Program7.menus" :key="i" class="my-7">
                                        <h4>{{menu.menu_name}}</h4>
                                        <v-data-table v-model="selected7" :single-select="false" :headers="headers"
                                            :items="menu.sub_menu" item-key="id" show-select class="elevation-1">

                                            <template v-slot:item.action="{ item }">
                                                <v-icon class="mr-2" @click="assignDepartment(item, Program7, selected7)">
                                                    mdi-spellcheck
                                                </v-icon>
                                            </template>
                                            <template v-slot:item.department_assigned="{item}">
                                                <span v-if="item.department_assigned.length == 0">No Departments</span>
                                                <span>{{ `${item.department_assigned.map(el => el.text) }`}}</span>
                                            </template>
                                        </v-data-table>
                                    </div>
                                    <div class="py-5">
                                        <button class="save_button"
                                            @click="submit(Program7.program, selected7)">Save</button>
                                    </div>
                                </v-tab-item>

                                <v-tab-item v-if="Program8">
                                    <div v-for="(menu, i) in  Program8.menus" :key="i" class="my-7">
                                        <h4>{{menu.menu_name}}</h4>
                                        <v-data-table v-model="selected8" :single-select="false" :headers="headers"
                                            :items="menu.sub_menu" item-key="id" show-select class="elevation-1">
                                            <template v-slot:item.action="{ item }">
                                                <v-icon class="mr-2" @click="assignDepartment(item, Program8, selected8)">
                                                    mdi-spellcheck
                                                </v-icon>
                                            </template>
                                            <template v-slot:item.department_assigned="{item}">
                                                <span v-if="item.department_assigned.length == 0">No Departments</span>
                                                <span>{{ `${item.department_assigned.map(el => el.text) }`}}</span>
                                            </template>
                                        </v-data-table>
                                    </div>
                                    <div class="py-5">
                                        <button class="save_button"
                                            @click="submit(Program8.program, selected8)">Save</button>
                                    </div>
                                </v-tab-item>

                                <v-tab-item v-if="Program9">
                                    <div v-for="(menu, i) in  Program9.menus" :key="i" class="my-7">
                                        <h4>{{menu.menu_name}}</h4>
                                        <v-data-table v-model="selected9" :single-select="false" :headers="headers"
                                            :items="menu.sub_menu" item-key="id" show-select class="elevation-1">

                                            <template v-slot:item.action="{ item }">
                                                <v-icon class="mr-2" @click="assignDepartment(item, Program9, selected9)">
                                                    mdi-spellcheck
                                                </v-icon>
                                            </template>
                                            <template v-slot:item.department_assigned="{item}">
                                                <span v-if="item.department_assigned.length == 0">No Departments</span>
                                                <span>{{ `${item.department_assigned.map(el => el.text) }`}}</span>
                                            </template>
                                        </v-data-table>
                                    </div>
                                    <div class="py-5">
                                        <button class="save_button"
                                            @click="submit(Program9.program, selected9)">Save</button>
                                    </div>
                                </v-tab-item>

                                <v-tab-item v-if="Program10">
                                    <div v-for="(menu, i) in  Program10.menus" :key="i" class="my-7">
                                        <h4>{{menu.menu_name}}</h4>
                                        <v-data-table v-model="selected10" :single-select="false" :headers="headers"
                                            :items="menu.sub_menu" item-key="id" show-select class="elevation-1">

                                            <template v-slot:item.action="{ item }">
                                                <v-icon class="mr-2" @click="assignDepartment(item, Program10,selected10)">
                                                    mdi-spellcheck
                                                </v-icon>
                                            </template>
                                            <template v-slot:item.department_assigned="{item}">
                                                <span v-if="item.department_assigned.length == 0">No Departments</span>
                                                <span>{{ `${item.department_assigned.map(el => el.text) }`}}</span>
                                            </template>
                                        </v-data-table>
                                    </div>
                                    <div class="py-5">
                                        <button class="save_button"
                                            @click="submit(Program10.program, selected10)">Save</button>
                                    </div>
                                </v-tab-item>

                            </v-tabs>

                        </div>
                    </div>
                </div>
          <div v-else>
You cannot assign menu to a user that is not active
          </div>
                </div>
                </div>
                <v-dialog persistent v-model="showAddDepartment" max-width="500px">
            <v-card>
                <v-card-title style="padding-bottom:0 !important; margin:0 !important">
                    Assign Department(s)
                    <v-spacer></v-spacer>
                <span style="color: red; cursor: pointer; font-size: 25px" @click="close"><v-icon>mdi-close-circle</v-icon></span>
                </v-card-title>
                <!-- <div style="display: flex; justify-content: space-between;">
                    <h6 class="title-h5 mb-4">Assign Departments to {{menu.menu_name}}</h6>
                    <span style="color: red; cursor: pointer; font-size: 25px" @click="close"><v-icon>mdi-close-circle</v-icon></span>
                </div> -->
        <v-divider></v-divider>
                <v-card-text>
                    <v-alert color="primary" border="left" dark prominent>
                        To assign department(s) to {{staffData.prefix}} {{staffData.surname}} {{staffData.name}} to administer <strong>{{menu.menu_name}}</strong>, you will need to select the faculty to drop down departments. <br><strong>Note</strong> that you can select departments accros different faculties.
                    </v-alert>
                    <div class="col-12">
                        <v-select outlined required v-model="faculty" :items="faculties" label="Select Faculty" @click=""
                            ></v-select>
                    </div>
                    <div class="col-12" style="margin-top:-20px">
                        <v-combobox :disabled="disabled" v-model="department" :items="departments" label="Select Department" multiple chips>
                        </v-combobox>
                        <!-- <v-select outlined v-model="department" required :items="departments" label="Select Department"
                            class="mr-3"></v-select> -->
                    </div>
                     
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="close"
          >
            Close
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="handleAssignDepartment"
          >
            Assign Department(s)
          </v-btn>
               <!-- <div class="col-md-6" style="text-align: right; margin:auto"> -->
                    <!-- <button @click="handleAssignDepartment" class="button-sub2" type="submit">Assign Department</button> -->
                <!-- </div> -->
                </v-card-actions>
            </v-card>
        </v-dialog>
</div>

    </div>
</template>

<script>

/**
 * 
 * TODO : Filter departments when faculty is selected based on the department for programs. already we have the program in gloabalSelectedProgramId
 * 
 */

import axios from 'axios';
import { exit } from 'process';
import Swal from 'sweetalert2';
// import {} from 'uuid';
import { allDepartments, allFaculties } from "@Appmart/utility";

export default {
    data() {
        return {
                tab: "password",
            showAddDepartment: false,
            gloabalSelectedProgramId :'',
            selected1: [],
            selected2: [],
            selected3: [],
            selected4: [],
            selected5: [],
            selected6: [],
            selected7: [],
            selected8: [],
            selected9: [],
            selected10: [],
            // desserts: null,
            Program1: null,
            Program2: null,
            Program3: null,
            Program4: null,
            Program5: null,
            Program6: null,
            Program7: null,
            Program8: null,
            Program9: null,
            Program10: null,
            disabled: true,
            programNames: [],
            headers: [
                // {
                //     text: 'Menu',
                //     value: 'menu'
                // },
                {
                    text: 'Menu Name',
                    value: 'menu_name'
                },
                {
                    text: '',
                    value: ''
                },
                {
                    text: 'Department',
                    value: 'department_assigned'
                },
                {
                    text: 'Assign Department',
                    value: 'action'
                }
            ],
            selectionType: "leaf",
            selected: [],
            programMenus: [],
            desserts: [],
            staffData: {},
            MenuArr: [],
            department: '',
            faculty: '',
            facultyHolder: [],
            faculties: [],
            departments: [],
            departmentsHolder: [],
            menu: {},
            selectectedMenu: null,
            arrModel: null,
            activeItem: null
        }
    },
    watch: {
        faculty(val) {
            console.log(val);
        this.populateRightDepartments(val);
            if(val){
                this.disabled = false;
            }
            // if (val && this.department) {
            //     this.disableFile = false;
            // }
        },
    },
    beforeMount() {
        this.staffData = JSON.parse(localStorage.getItem('_@$wsar15+0-'))
        // this.fetchMenus() 
        // this.fetchProgram()
        this.fetchMenusByProgram();
        this.fetchFaculties();
        this.fetchDepartments();

    },
    mounted() {

    },
    methods: {
        handleAssignDepartment() {
            if(!this.department || !this.faculty) return Swal.fire({icon: 'error', text: 'Please select department and faculty to proceed', title: 'Oops..'});
                console.log(this.selectectedMenu);
            
            this.selectectedMenu.menus.forEach(menu => {
                if (menu.sub_menu[menu.sub_menu.indexOf(this.menu)]){
                    menu.sub_menu[menu.sub_menu.indexOf(this.menu)].department_assigned = this.department;
                    return;
                }
            })

            this.arrModel.push(this.activeItem);

            this.showAddDepartment = false;
            this.department = '';
            this.faculty = '';
        },
        closeModal() {
            this.showAddDepartment = false;
        },
        assignDepartment(item, selectectedMenu, arrModel) {
            this.arrModel = arrModel;
            this.activeItem = item;
            this.disabled = true;
            console.log('Here thos',arrModel); 

            this.gloabalSelectedProgramId = selectectedMenu.program.id
            this.department = item.department_assigned
            this.departments = []
            this.menu = item;
            this.selectectedMenu = selectectedMenu;
            this.showAddDepartment = true;
            console.log('selected Items',item);
        },

        async submit(program, selectedMenus) {
            console.log(this.staffData);
            try {
                // console.log(program, selectedMenus);

                if (selectedMenus.length == 0) return Swal.fire({
                    icon: 'error',
                    text: 'Please select a menu',
                    title: 'Oops..'
                });

                const dataMenu = selectedMenus.map(menu => {
                    return {
                        staffId: this.staffData._id,
                        program_id: program.id,
                        //Submenu assigned
                        sub_menu: menu,
                        department_assigned: menu.department_assigned.map(el => el.value.id)
                    }
                });

                // console.log('DATA', dataMenu);
    var url = `${this.$appUrl}/assignMenu`;
                const res = await axios.post(url, {
                    menus: dataMenu
                });

                if (res.data.code === 's200') {
                    Swal.fire({
                        icon: 'success',
                        text: res.data.message,
                        title: 'Successful'
                    });
                } else {
                    Swal.fire({
                        icon: 'error',
                        text: res.data.message,
                        title: 'Oops..'
                    });

                }

            } catch (error) {
                console.log(error);
            }

            /*
                        {
                staff_id: String,
                program_id: { type: mongoose.Schema.Types.ObjectId, ref: 'Program'},
                //Submenu assigned
                sub_menu: {
                    menu_name: String,
                    menu_icon: String,
                    menu_link: String,
                    menu_id: { type: mongoose.Schema.Types.ObjectId, ref: "Menu"},
                    created_on: { type: Date, default: Date.now()},
                    created_by: String,
                    status: { type: Number, default: 1}
                },
                //Parent Id
                menu_id: { type: mongoose.Schema.Types.ObjectId, ref: 'Menu'},
                dateAssigned: { type: Date, default: Date.now()},
                //Assigned departments
                department_assigned: [
                    {
                        type: mongoose.Schema.Types.ObjectId,
                        ref: 'Department'
                    }
                ],
                //1 => active, 0 => inactive
                status: { type: Number, default: 1
                }
            } 
                */
            // console.log(id);
        },
       async fetchAssignedMenus(){
        console.log(this.staffData);
            try {
                    var url = `${this.$appUrl}/myMenus`;
                const res = await axios.post(url, {staffId: this.staffData._id});

                    console.log('RESPONSE',this.programMenus);
                res.data.message.data.forEach(menu => {
                    this.programMenus.forEach((pmenu, i) => {
                        if(pmenu.program.id === menu.program_id){

                            this[`selected${i + 1}`].push(menu.sub_menu);
                            return;
                        }
                    })
                    
                })

                // const pmenus = [];
                // this.programMenus.forEach(el => {
                //     pmenus.push(...el.menus)
                // });

                res.data.message.data.forEach(menu => {
                    this.programMenus.forEach(pmenu =>{
                        pmenu.menus.forEach(cmenu => {
                            cmenu.sub_menu.forEach(sub => {
                                if(sub.id === menu.sub_menu.id){
                                    sub.department_assigned = menu.sub_menu.department_assigned;
                                }
                            })
                        })
                    })

                })

            } catch (error) {
                console.log(error);
            }
        },  
        populateRightDepartments(val) {
            if(val !=''){
            const faculty =  this.facultyHolder.find((el) => el.name == val);
            console.log('drough',this.departmentsHolder);
             
            const facultyId = faculty._id;
            const departmentDatas = this.departmentsHolder.filter(
                (el) => el.faculty._id === facultyId
            );
            const departments = departmentDatas.map((el) => {
                // if()
                return {
                    text: el.name,
                    value: {id: el._id, name: el.name}
                }
            });
            this.departments = departments;
            }
        },
        close() {
            this.showAddDepartment = false;
            this.faculty = '';
            this.department = '';
            // console.log('close');
        },
        async fetchFaculties() {
            try {
                const res = await allFaculties().then(response => {
  return response;
}).catch(e => {
  console.log(e);
})

                const facultyNames = res.data.message.map((el) => el.name);
                const datas = res.data.message.map((el) => el);
                // console.log('Faculty',res);
                this.facultyHolder = datas;

                this.faculties = facultyNames;
            } catch (error) {
                console.log(error);
            }
        },
        async fetchDepartments() {
            try {
                const res = await allDepartments().then(response => {
  return response;
}).catch(e => {
  console.log(e);
})
                this.departmentsHolder = res.data.message;
            } catch (error) {
                console.log(error);
            }
        },
        assign(item) {
            console.log(item)
        },
        async fetchMenusByProgram() {
            try {
            var url = `${this.$appUrl}/fetchProgramMenu`;
                const res = await axios.get(url);
                if (res.data.code == 's200') {
                    this.programNames = res.data.message.programNames;
                    res.data.message.data.forEach(menu => {
                        // console.log('Menus', menu)
                        menu.menus.forEach(men => {
                            men.sub_menu.forEach((sub, i) => {
                                sub['department_assigned'] = [];
                                sub['id'] = `${i+1}${sub.menu_name.split(' ').join('').toLowerCase()}${men._id}`
                            })
                        })
                    });

                    this.programMenus = res.data.message.data;

                    for (let i = 0; i < this.programMenus.length; i++) {
                        console.log('Program' + ' ' + i, this.programMenus[i])
                        this[`Program${i + 1}`] = this.programMenus[i];

                    }
                    console.log('Program Menu', this.programMenus)
                    this.fetchAssignedMenus();


                }
            } catch (error) {
                console.log(error);
            }
        },
        splitMenuBasedOnProgram() {

        }
    }
}
</script>

<style lang="scss" scoped>
.btn-bot{
   position:absolute; 
   margin-left:-35px;
   left:35%;
   bottom:10px;
}
.label {
    padding: 20px 0;
    display: flex;

    h4 {
        font-family: Proxima Nova;
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 20px;
        color: #4f4f4f;
        padding-left: 40px;
    }

    button {
        width: 45px;
        height: 45px;
        background: #d6ecf5;
        border-radius: 3px;
    }
}

.save_button {
    padding: 15px;
    width: 149px;
    height: 45px;
    background: #006794;
    border-radius: 3px;

    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    align-items: center;
    text-align: center;
    color: #ffffff;
}

.contain {
    width: 100%;
    height: auto;
    background: #ffffff;
}

.menu_wrap {
    max-height: 500px;
    overflow-y: scroll;
}

.menu_wrap::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #F5F5F5;
}

.menu_wrap::-webkit-scrollbar {
    width: 8px;
    background-color: #F5F5F5;
}

.menu_wrap::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    background-color: #a7a7a7;
}
.button-sub2 {
    padding: 15px;
    height: auto;
    display: block;
    width: 100%;
    background: #006794;
    border-radius: 3px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    color: #FFFFFF;
}
.bio_details {
    h6 {
        margin-top: 20px;

        strong {
            font-family: Proxima Nova;
            font-style: normal;
            font-weight: bold;
            font-size: 20px;
            line-height: 114%;
            text-align: center;
            color: #4f4f4f;
        }
    }

    h5 {
        margin-top: 19px;

        span {
            font-family: Proxima Nova;
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 114%;
            color: #828282;
        }
    }
}
.setup_tap {
    border-left: 1px solid #e5e5e5;
}
#avatar {
    /* This image is 687 wide by 1024 tall, similar to your aspect ratio */
    background-image: url('../assets/user.svg');
    
    /* make a square container */
    width: 150px;
    height: 150px;

    /* fill the container, preserving aspect ratio, and cropping to fit */
    background-size: cover;

    /* center the image vertically and horizontally */
    background-position: top center;

    /* round the edges to a circle with border radius 1/2 container size */
    border-radius: 50%;
    margin-bottom:20px !important
}
</style>
