<template>
    <div class="home">
    <div class="row">
        <div class="col-md-12" style="margin-top:70px !important; height:70px !important; padding:30px 0 0px 10px; position:fixed; z-index: 1; background:#fff; border-bottom: solid 1px #ccc; padding-left:40px">
            <span style="color:#777; font-size:16px;padding:10px;">Department Setting</span>
        </div>
    </div>
    <div style="background: #f5f5f5; margin-top:150px; " class=" container-fluid row ">
        <v-row style="margin:0 !important; padding:0 !important">
    <v-col cols="12" style="">

        <v-card class="mx-auto mt-6">
      <!-- <div class="pa-4" style="background: #c0e7fe;">
        <h4>Degree Settings <span v-if="department"> {{'For ' + department.name}}</span></h4>
      </div> -->
      <!-- <div v-if="showsetup" class="pa-7">
        <div style="max-width: 600px; margin: 0 auto">
          <v-autocomplete dense :items="departments" v-model="department" outlined label="Select Department"></v-autocomplete>
          <v-btn color="#c0e7fe" :disabled="loading" :loading="loading" block @click="next" class="ma-auto pa-3">Next</v-btn>
        </div>
      </div> -->
    
      <div v-if="!showsetup">
        <v-card-subtitle style="font-size:20px">
            {{department.name }} Setup
        </v-card-subtitle>
        <v-divider style="margin:0"></v-divider>
        <div class="col-md-8">
          <div>
            <!-- <button style="padding: .9rem; background: #ccc" @click="back">
              <img src="" alt="" srcset="" />
            </button> -->
          </div>
        </div>
        <v-tabs max-width="700">
          <v-tab v-for="(name, i) in programNames" :key="i">{{name}}</v-tab>
    
          <v-tab-item>
            <v-card class="pa-6" v-if="degreeProgram1.length == 0 ? false : true">
              <v-row>
                <v-col class="col-md-12 col-lg-6">
                  <v-expansion-panels focusable multiple>
                    <v-expansion-panel v-for="(prod, i) in degreeProgram1" :key="i">
                      <v-expansion-panel-header>{{prod.name}}</v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <div class="pa-3">
                          <v-text-field dense v-model="prod.code" label="Enter Admission Code" outlined></v-text-field>
                          <v-text-field type="number" outlined dense v-model="prod.noOfYear" label="Enter Number Of Year Of Study"></v-text-field>
                          <v-text-field outlined dense v-model="prod.certificateName" label="Certificate Name (Acronym)"></v-text-field>
                        </div>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                  <v-btn @click="submit('1')" block class="mt-7">Save</v-btn>
                </v-col>
              </v-row>
            </v-card>
            <div v-else class="text-center pa-6">
              <img style="height: 250px; width: 250px" src="" alt=""> <br> <strong>No degree has been setup for this program yet</strong>
            </div>
          </v-tab-item>
    
          <v-tab-item>
            <v-card class="pa-6" v-if="degreeProgram2.length == 0 ? false : true">
              <v-row>
                <v-col class="col-md-12 col-lg-6">
                  <v-expansion-panels focusable multiple>
                    <v-expansion-panel v-for="(prod, i) in degreeProgram2" :key="i">
                      <v-expansion-panel-header>{{prod.name}}</v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <div class="pa-3">
                          <strong class="mb-2 d-block">Code</strong>
                          <v-text-field dense v-model="prod.code" label="Enter Code" outlined></v-text-field>
                          <strong class="mb-2 d-block">Number of years</strong>
                          <v-text-field outlined dense v-model="prod.noOfYear" label="Enter Number Of Year"></v-text-field>
                          <strong class="mb-2 d-block">Certificate Name</strong>
                          <v-text-field outlined dense v-model="prod.certificateName" label="Enter Certificate Name"></v-text-field>
                        </div>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                  <v-btn @click="submit('2')" block class="mt-7">Submit</v-btn>
                </v-col>
              </v-row>
            </v-card>
            <div v-else class="text-center pa-6">
              <img style="height: 250px; width: 250px" src="" alt=""> <br> <strong>No degree has been setup for this program yet</strong>
            </div>
          </v-tab-item>
    
          <v-tab-item>
            <v-card class="pa-6" v-if="degreeProgram3.length == 0 ? false : true">
              <v-row>
                <v-col class="col-md-12 col-lg-6">
                  <v-expansion-panels focusable multiple>
                    <v-expansion-panel v-for="(prod, i) in degreeProgram3" :key="i">
                      <v-expansion-panel-header>{{prod.name}}</v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <div class="pa-3">
                          <strong class="mb-2 d-block">Code</strong>
                          <v-text-field dense v-model="prod.code" label="Enter Code" outlined></v-text-field>
                          <strong class="mb-2 d-block">Number of years</strong>
                          <v-text-field outlined dense v-model="prod.noOfYear" label="Enter Number Of Year"></v-text-field>
                          <strong class="mb-2 d-block">Certificate Name</strong>
                          <v-text-field outlined dense v-model="prod.certificateName" label="Enter Certificate Name"></v-text-field>
                        </div>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                  <v-btn @click="submit('3')" block class="mt-7">Submit</v-btn>
                </v-col>
              </v-row>
            </v-card>
            <div v-else class="text-center pa-6">
              <img style="height: 250px; width: 250px" src="" alt=""> <br> <strong>No degree has been setup for this program yet</strong>
            </div>
          </v-tab-item>
    
          <v-tab-item>
            <v-card class="pa-6" v-if="degreeProgram4.length == 0 ? false : true">
              <v-row>
                <v-col class="col-md-12 col-lg-6">
                  <v-expansion-panels focusable multiple>
                    <v-expansion-panel v-for="(prod, i) in degreeProgram4" :key="i">
                      <v-expansion-panel-header>{{prod.name}}</v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <div class="pa-3">
                          <strong class="mb-2 d-block">Code</strong>
                          <v-text-field dense v-model="prod.code" label="Enter Code" outlined></v-text-field>
                          <strong class="mb-2 d-block">Number of years</strong>
                          <v-text-field outlined dense v-model="prod.noOfYear" label="Enter Number Of Year"></v-text-field>
                          <strong class="mb-2 d-block">Certificate Name</strong>
                          <v-text-field outlined dense v-model="prod.certificateName" label="Enter Certificate Name"></v-text-field>
                        </div>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                  <v-btn @click="submit('4')" block class="mt-7">Submit</v-btn>
                </v-col>
              </v-row>
            </v-card>
            <div v-else class="text-center pa-0">
              <img style="height: 250px; width: 250px" src="" alt=""> <br> <strong>No degree has been setup for this program yet</strong>
            </div>
          </v-tab-item>
    
          <v-tab-item>
            <v-card class="pa-6" v-if="degreeProgram5.length == 0 ? false : true">
              <v-row>
                <v-col class="col-md-12 col-lg-6">
                  <v-expansion-panels focusable multiple>
                    <v-expansion-panel v-for="(prod, i) in degreeProgram5" :key="i">
                      <v-expansion-panel-header>{{prod.name}}</v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <div class="pa-3">
                          <strong class="mb-2 d-block">Code</strong>
                          <v-text-field dense v-model="prod.code" label="Enter Code" outlined></v-text-field>
                          <strong class="mb-2 d-block">Number of years</strong>
                          <v-text-field outlined dense v-model="prod.noOfYear" label="Enter Number Of Year"></v-text-field>
                          <strong class="mb-2 d-block">Certificate Name</strong>
                          <v-text-field outlined dense v-model="prod.certificateName" label="Enter Certificate Name"></v-text-field>
                        </div>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                  <v-btn @click="submit('5')" block class="mt-7">Submit</v-btn>
                </v-col>
              </v-row>
            </v-card>
            <div v-else class="text-center pa-0">
              <img style="height: 250px; width: 250px" src="" alt="">
              <br> <strong>No degree has been setup for this program yet</strong>
            </div>
          </v-tab-item>
        </v-tabs>
      </div>
    </v-card>

        </v-col>
        </v-row>

        <!-- <div style="margin:auto; padding:20px; margin-top:100px" class="col-md-5" v-if="showsetup">
        <v-card elevation="3" height="auto" >
            <v-divider style="margin-top:0px"></v-divider>
            <v-col cols="11" style="margin:auto;padding-top:45px" pa-4>

            <div style="max-width: 600px; margin: 0 auto">
          <v-autocomplete dense :items="departments" v-model="department" outlined label="Select Department"></v-autocomplete>
          <v-btn color="#c0e7fe" :disabled="loading" :loading="loading" block @click="next" class="ma-auto pa-3">Next</v-btn>
        </div>
        </v-col>

            </v-card>
            </div> -->

        </div>

    
    </div>
    </template>
    
    <script>
    import axios from 'axios'
    import Swal from 'sweetalert2'
    import {allDepartments,allFaculties,degree,allProgrames} from '@Appmart/utility'

    // import axios from 'axios'
    // import Tab from './Tab.vue'
    
    export default {
      data: () => ({
        degreeList: [],
        loading: false,
        programNames: [],
        programs: [],
        degrees: [],
        showsetup: true,
        departments: [],
        department: '',
        degreeProgram1: [],
        degreeProgram2: [],
        degreeProgram3: [],
        degreeProgram4: [],
        degreeProgram5: []
      }),
    
      computed: {
    
      },
    
      watch: {
    
      },
    
      beforeMount() {
               this.fetchDegree();
        this.fetchProgram();
        this.fetchDepartments();
      },
   
      mounted() {
        this.next()
        // console.log('Hutls',this.degreeList);
      },
    
      methods: {
        next() {
        //   this.loading = true;
        //   if (!this.department) {
        //     return Swal.fire({
        //       icon: 'info',
        //       text: 'Please Select Department',
        //       title: 'Oops..'
        //     })
        //   }
         // console.log('chuka',this.department);

          const data = window.atob(this.$route.params.id)
                this.departmentInfo = JSON.parse(data)

                this.department = {id:this.departmentInfo.id,name:this.departmentInfo.department}
                console.log('chuka',this.department);

          this.buildDegrees()
    
          this.showsetup = false;
        },
        back() {
          this.showsetup = true;
          this.department = '';
          this.loading = false;
        },


        async fetchProgram() {
          try {
            

            const activeProgrames = await allProgrames().then(activeSet => {
  return activeSet;
}).catch(e => {
  console.log(e);
  return
})

console.log('Active programs',activeProgrames)

activeProgrames.data.message.forEach(program => {
                this.programs.push({
                  text: program.name,
                  value: program._id
                })
                this.programNames.push(program.name)
              });

              console.log('hhhhhhhhsssss', this.programNames, activeProgrames)
              this.fetchDegree()

            // const res = await axios.get('http://localhost:5000/fetchprograms');
            // // console.log('RESET', res)
            // if (res.data.code == '00') {
            //   res.data.data.forEach(program => {
            //     this.programs.push({
            //       text: program.program,
            //       value: program._id
            //     })
            //     this.programNames.push(program.program)
            //   });
    
            //   this.fetchDegree()
            // } else {
            //   console.log("ERROR")
            // }
          } catch (error) {
            console.log(error)
          }
        },
    
        async fetchDepartments() {
          try {
            const activeDepartments = await allDepartments().then(activeSet => {
  return activeSet;
}).catch(e => {
  console.log(e);
  return
})
         
              const departments =activeDepartments.data.message.map(el => {
                return {
                  text: el.name,
                  value: {
                    name: el.name,
                    id: el._id
                  }
                }
              })
    
              this.departments.push(...departments)
        
          } catch (error) {
            console.log(error);
          }
        },
    
        async submit(num) {
          console.log(this[`degreeProgram${num}`])
          //validate payload
          this[`degreeProgram${num}`].forEach(el => {
            const vals = Object.values(el);
    
            if (vals.includes(' ') || vals.includes('')) {
              return Swal.fire({
                icon: 'error',
                text: 'Please make sure you setup every degree to proceed',
                title: 'Oops..'
              })
            }
          })
    
          const degrees = this[`degreeProgram${num}`].map(el => {
            return {
              name: el.name,
              id: el.id,
              noOfYear: el.noOfYear,
              code: el.code,
              certificateName: el.certificateName
            }
          });
    
          const data = {
            departmentId: this.department.id,
            settings: {
              programId: this[`degreeProgram${num}`][0].programId,
              degrees
            }
          }
    
          try {
            const url = process.env.VUE_APP_SETTINGS_V1_API_ENDPOINT2+'/department-setup'
            const res = await axios.post(url, data);
    
            if (res.data.code == 's200') {
              Swal.fire({
                icon: 'success',
                text: 'Department setup was successful',
                title: 'Successful!!'
              });
              // this.department = ''
            } else {
              Swal.fire({
                icon: 'error',
                message: res.data.message,
                title: 'Oops..'
              })
            }
    
          } catch (error) {
            console.log(error);
          }
    
          console.log(data)
          /*
          {
              "name": "Degree",
              "programId": "61ef4514a88631b45cd21f31",
              "id": "630f6fa118662b8fd5b5205b",
              "noOfYear": "8",
              "code": "j",
              "certificateName": "0"
          }
            "departmentId": {
              "$oid": "61d7902565e95899bcf1a394"
            },
            "settings": [
              {
                "programId": "61ef4514a88631b45cd21f31",
                "degrees": [
                  {
                    "code": "401",
                    "noOfYear": 2,
                    "id": "630f6fa118662b8fd5b5205b",
                    "name": "BSC"
                  },
                  {
                    "code": "901",
                    "noOfYear": 4,
                    "id": "630f6fb718662b8fd5b5205e",
                    "name": "BEC"
                  }
                ]
              },
    
            ],
    
            },
            "__v": 0*/
    
        },
    
        buildDegrees() {
          this.degreeProgram1 = this.degrees.filter(deg => deg.program == this.programs[0]?.value).map(mdeg => {
            return {
              name: mdeg.name,
              programId: this.programs[0]?.value,
              id: mdeg._id,
              noOfYear: '',
              code: '',
              certificateName: ''
            }
          });
    
          this.degreeProgram2 = this.degrees.filter(deg => deg.program == this.programs[1]?.value).map(mdeg => {
            return {
              name: mdeg.name,
              programId: this.programs[1]?.value,
              id: mdeg._id,
              noOfYear: '',
              code: '',
              certificateName: ''
            }
          })
    
          this.degreeProgram3 = this.degrees.filter(deg => deg.program == this.programs[2]?.value).map(mdeg => {
            return {
              name: mdeg.name,
              programId: this.programs[2]?.value,
              id: mdeg._id,
              noOfYear: '',
              code: '',
              certificateName: ''
            }
          })
    
          this.degreeProgram4 = this.degrees.filter(deg => deg.program == this.programs[3]?.value).map(mdeg => {
            return {
              name: mdeg.name,
              programId: this.programs[3]?.value,
              id: mdeg._id,
              noOfYear: '',
              code: '',
              certificateName: ''
            }
          });
    
          this.degreeProgram5 = this.degrees.filter(deg => deg.program == this.programs[4]?.value).map(mdeg => {
            return {
              name: mdeg.name,
              programId: this.programs[4]?.value,
              id: mdeg._id,
              noOfYear: '',
              code: '',
              certificateName: ''
            };
    
          })

          this.fetchDepartmentSetup();

          console.log('Degreee slls', this.degreeProgram1, this.degreeProgram2, this.degreeProgram3);
    
        },
    
        async fetchDepartmentSetup() {
          try {
            const url = process.env.VUE_APP_SETTINGS_V1_API_ENDPOINT2+'/fetch-department-setup'

            const res = await axios.post(url, {
              departmentId: this.department.id
            });
    
            console.log(res);
    
            if (res.data.code == 's200') {
              
              const degreesSettings = [];
              res.data.message.settings.forEach(setting => {
                setting.degrees.forEach(el => {
                  degreesSettings.push({...el, programId:setting.programId})
                  
                })
              })
    
              degreesSettings.forEach(degSetting => {
                const index = this.programs.findIndex(el => el.value == degSetting.programId);
    
                if(index + 1 >= 1){
                  const idx = this[`degreeProgram${index + 1}`].findIndex(el => el.id === degSetting.id);
    
                  console.log(index, idx);
                  this[`degreeProgram${index + 1}`][idx].noOfYear = degSetting.noOfYear;
                  this[`degreeProgram${index + 1}`][idx].code = degSetting.code;
                  this[`degreeProgram${index + 1}`][idx].certificateName = degSetting.certificateName;
                }
              });
              this.loading = false;
    
              }
          } catch (error) {
            console.log(error);
          }
        },
    
        // async fetchDegree() {
        //   try {
        //     const res = await axios.get('http://localhost:5000/getdegrees');
        //     // console.log('Deg', res);
        //     if (res.data.code == 's200') {
        //       this.degrees = res.data.message;
        //       this.buildDegrees()
        //     }
    
        //   } catch (error) {
        //     console.log(error);
        //   }
    
        // },

        async fetchDegree() {
            try {
        const programDegree = await degree()
          .then((activeSet) => {
            return activeSet;
          })
          .catch((e) => {
            console.log(e);return
          });

          this.degrees = programDegree.data.message;
          this.buildDegrees()
          console.log(degree);
        // }
      } catch (error) {
        console.log(error);
      }
        //   try {
        //     const res = await axios.get('http://localhost:5000/getdegrees');
        //     // console.log('Deg', res);
        //     if (res.data.code == 's200') {
        //       res.data.message.forEach(el => {
        //         this.degrees.push({
        //           text: el.name,
        //           value: {
        //             id: el._id,
        //             name: el.name
        //           }
        //         })
        //       });
    
        //     }
    
        //   } catch (error) {
        //     console.log(error);
        //   }
    
        },
    
        // components: {
        //   // Tab,
        // }
      }
    }
    </script>
    
    <style>
    ._btn {
      background-color: #0370C7;
      width: 120px;
      margin-right: 15px !important;
      padding: 10px;
      color: #fff;
      font-weight: bold;
      border-radius: 5px;
    }
    
    ._btn:active {
      transform: scale(.9);
    }
    </style>
    