<template>
<div class="home">
  <v-dialog v-model="dialogLoad" width="200">
    <v-card>
      <v-progress-linear color="#006794" indeterminate rounded height="6"></v-progress-linear>
    </v-card>
  </v-dialog>
  <div class="row">
    <div class="col-md-12" style="margin-top:70px !important; height:70px !important; padding:30px 0 0px 10px; position:fixed; z-index: 1; background:#fff; border-bottom: solid 1px #ccc; padding-left:40px">
      <span style="color:#777; font-size:18px;padding:5px;">Utme</span>/<span style="padding:5px; color:#555">Jamb Report</span>
    </div>
  </div>

  <div style="background: #f5f5f5; margin-top:180px" class=" container-fluid"></div>
  <v-row style="margin:0 !important; padding:0 !important">
    <v-card class="pt-8">
      <v-col cols="12" style="">

        <div class="row">
          <div class="col-12">
            <div class="row">
             
              <div class="col-lg-3 col-md-6 col-sm-12  py-0">
                <strong>Jamb Reg Number</strong>
                <v-text-field :clearable="clearable" v-model="filter.j_reg" class="mt-3" label="Enter Jamb reg" outlined dense></v-text-field>
              </div>
              
              <div class="col-md-2 py-0">
                  <strong>Admission Session</strong>
                  <v-select :clearable="clearable" :items="sessions" v-model="filter.session" class="mt-3" label="Select Admission Session" outlined dense></v-select>
                </div>

                <div class="col-lg-3 col-md-6 col-sm-12 py-0">
                  <strong>Type</strong>
                  <v-select :clearable="clearable" :items="types" v-model="filter.type" class="mt-3" label="Select Type" outlined dense></v-select>
                </div>

                <div class="col-lg-3 col-md-6 col-sm-12 py-0">

                  <div style="margin-top: 2.2rem">
                    <v-btn @click="clear" class="mr-1 btn-clear">clear</v-btn>

                    <v-btn @click="submit" :loading="loading"
      :disabled="loading" class="btn-search" color="#006794">Search</v-btn>
                  </div>

                </div>
              

              </div>
            </div>
            <div class="col-md-8 "> <strong>Number of result {{datas.length }}</strong></div>
            <div class="col-md-4 mb-8">
              <v-text-field v-model="search" append-icon="mdi-magnify" label="Search For Invoice" single-line hide-details></v-text-field>
            </div>
          </div>
          <v-data-table :search="search" :headers="headers" :items="datas">
            
          </v-data-table>
      </v-col>
    </v-card>
  </v-row>

</div>
</template>

  
  
<script>
import axios from 'axios';
import Swal from 'sweetalert2';
// import axios from 'axios';
// import Swal from 'sweetalert2';
export default {
  data() {
    return {
      search: '',
      show: false,
      dialogLoad: false,
      loading: false,
   
      clearable: true,
      filter: {
        j_reg: '',
        type: '',
        session:''
      },
      types: [{
        text: 'UTME',
        value: 1
      }, {
        text: 'Direct Entry',
        value: 2
      }],
      sessions: ['2022','2023'],
      headers: [{
          text: 'Name',
          value: 'name'
        },
        {
          text: 'Jamb Reg Number',
          value: 'j_reg'
        },
        {
          text: 'Recomendation',
          value: 'recomend'
        },
        //state, department, total, type
        {
          text: 'status',
          value: 'status'
        },
        {
          text: 'Total',
          value: 'total',
        },
        {
          text: 'Type',
          value: 'type'
        },
        {
          text: 'Status',
          value: 'status'
        },
        
      ],
      item: {
        el: {
          linkDate: '',
          datefinished: ''
        }
      },
      name: "",
      datas: []
    }
  },

  watch: {

  },

  beforeMount() {

  },
  methods: {
    clear() {
      this.filter = {
        type: '',
        j_reg: '',
        session:''
      };
    },

    async submit() {
      try {
if(this.filter.admission_session ==''){

  return  Swal.fire({icon: 'error', text:'Please Select Admission Session', title: 'Jamb Report'});

}
        this.datas = [];
        this.loading = true;
        const res=await axios.post(`${this.$appUrl}/jamb-report`, {payload: this.filter});

        if(res.data.code === 's200'){
          res.data.message.forEach(el => {
            this.datas.push({
              j_reg: el.j_reg,
              name: `${el.surname} ${el.name} ${el.othernames}`,
              dep: el.department,
              sta: el.state,
              total: el.total,
              type: el.type == 1 ? 'UTME' : 'Direct Entry',
              status: el.qualified == 1 ? 'Qualified' : 'Not Qualified',
              recomend:el.recommendation
            })
          });
          this.loading = false;
        }else{
        return  Swal.fire({icon: 'error', text: res.data.message, title: 'Oops..'});
        }
      } catch (error) {
        console.log(error);
      }
    },
  }
}
</script>
  
  
<style>
.btn-search {
  color: #fff !important;
  padding: 1.1rem !important;
  font-size: 10px !important;

}

.btn-clear {
  padding: 1.1rem !important;
  font-size: 10px !important;
}
</style>
