<template>
  <div>
    <div class="row">
            <div class="col-md-8" style="font-size:18px; padding-left: 20px;"> </div>
            <div class="col-md-4">
                <v-text-field v-model="search" append-icon="mdi-magnify" label="Search Admin User" single-line hide-details></v-text-field>
            </div>
        </div>
     <v-data-table :headers="headers" :items="dataToshow" :search="search"  class="elevation-1 mt-6"  v-if="table =='userProfile'">
       
         <template v-slot:[`item.sn`]="{ item }">
                        <tr>
                            <td class="table-txt">{{dataToshow.map(function(x) {return x._id; }).indexOf(item._id)+1}}</td>
                        </tr>
                    </template>
                    <template v-slot:[`item.dateProfiled`]="{ item }">
                        <tr>
                            <td class="table-txt" v-if="item.dateProfiled !=undefined">{{item.dateProfiled.split('.')[0]}}</td>
                        </tr>
                    </template>

                    <template v-slot:[`item.surname`]="{ item }">
                        <tr>
                            <td class="table-txt">{{item.surname}} {{item.name}} {{item.othernames}}</td>
                        </tr>
                    </template>
                    <template v-slot:[`item.admin_type`]="{ item }">
                        <tr>
                            <td class="table-txt" v-if="item.admin_type == '100'">App User</td>
                            <td class="table-txt" v-if="item.admin_type == '200'">Sys Admin</td>
                        </tr>
                    </template>
           <!--  <template v-slot:top>
                <v-toolbar flat>
                    <v-toolbar-title style="font-size:16px">List Admin Users</v-toolbar-title>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-text-field v-model="search" append-icon="mdi-magnify" label="Search Admin User" single-line hide-details></v-text-field>
                                        <v-spacer></v-spacer>
                    <v-dialog v-model="dialog" width="500px">
                        <template v-slot:activator="{ attrs }">

                            <v-btn class="mx-2" fab dark color="#006794" v-bind="attrs" small @click="searchAdmin">
                                <v-icon>
                                    mdi-plus
                                </v-icon>
                            </v-btn>

                        </template>
                       
                    </v-dialog>
                    <v-dialog v-model="dialogDelete" max-width="500px">
                        <v-card>
                            <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                                <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                                <v-spacer></v-spacer>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-toolbar>
            </template> -->
            <template v-slot:item.actions="{ item }">
                <!-- <v-icon small class="mr-2" @click="editItem(item)">
              mdi-pencil
            </v-icon>
            <v-icon small @click="deleteItem(item)">
              mdi-delete
            </v-icon> -->
            <div @click="redirectUserProfile(item)" style="cursor:pointer;">
            <!-- <span style="font-size:10px"> Profile</span> -->
        <v-icon  >
                    mdi-arrow-right-circle
                </v-icon>
            </div>
              
            </template>
            <template v-slot:no-data>
               
            </template>
        </v-data-table>



          <v-data-table :headers="headers1" :items="dataToshow"  class="elevation-1 mt-6" :search="search" v-if="table =='assignMenu'">
         <template v-slot:[`item.sn`]="{ item }">
                        <tr>
                            <td class="table-txt">{{dataToshow.map(function(x) {return x._id; }).indexOf(item._id)+1}}</td>
                        </tr>
                    </template>
                
           <!--  <template v-slot:top>
                <v-toolbar flat>
                    <v-toolbar-title style="font-size:16px">List Admin Users</v-toolbar-title>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-text-field v-model="search" append-icon="mdi-magnify" label="Search Admin User" single-line hide-details></v-text-field>
                                        <v-spacer></v-spacer>
                    <v-dialog v-model="dialog" width="500px">
                        <template v-slot:activator="{ attrs }">

                            <v-btn class="mx-2" fab dark color="#006794" v-bind="attrs" small @click="searchAdmin">
                                <v-icon>
                                    mdi-plus
                                </v-icon>
                            </v-btn>

                        </template>
                       
                    </v-dialog>
                    <v-dialog v-model="dialogDelete" max-width="500px">
                        <v-card>
                            <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                                <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                                <v-spacer></v-spacer>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-toolbar>
            </template> -->
            <template v-slot:item.actions="{ item }">
                <!-- <v-icon small class="mr-2" @click="editItem(item)">
              mdi-pencil
            </v-icon>
            <v-icon small @click="deleteItem(item)">
              mdi-delete
            </v-icon> -->
            <div @click="redirectMenuAssign(item)" style="cursor:pointer;">
            <!-- <span style="font-size:10px"> Assign Menu</span> -->
        <v-icon  >
            mdi-arrow-right-circle
                </v-icon>
            </div>
            </template>
            <template v-slot:no-data>
               
            </template>
        </v-data-table>
  </div>
</template>

<script>
export default {
props:{
    dataToshow:{Type:Array, default:[]},
        table:{Type:String},
        search:{Type:String}
},
itemsWithSno() {
      return this.dataToshow.map((d, index) => ({ ...d, sno: index + 1 }))
   },
data(){
    return{
headers: [{
                text: 'S/N',
                align: 'start',
                value: 'sn',
            },
            {
                text: 'Staff id',
                value: 'skola_id'
            },
            {
                text: 'Tittle',
                value: 'prefix',
            },
            {
                text: 'names',
                value: 'surname',
            },
            {
                text: 'Email',
                value: 'email'
            },
            {
                text: 'Date Profiled',
                value: 'dateProfiled'
            },
            {
                text: 'Profiled By',
                value: 'profiledBy'
            },

            {
                text: 'Type',
                value: 'admin_type'
            },
            {
                text: 'Status',
                value: 'status'
            },
            {
                text: 'Profile',
                value: 'actions',
                sortable: false
            },
        ],

        headers1: [{
                text: 'S/N',
                align: 'start',
                value: 'sn',
            },
            {
                text: 'Staff id',
                value: 'skola_id'
            },
            {
                text: 'Tittle',
                value: 'prefix',
            },
            {
                text: 'Surname',
                value: 'surname',
            },
            {
                text: 'Name',
                value: 'name'
            },

            {
                text: 'OtherNames',
                value: 'othernames'
            },
            {
                text: 'Email',
                value: 'email'
            },
            {
                text: 'Status',
                value: 'status'
            },
            {
                text: 'Menu',
                value: 'actions',
                sortable: false
            },
        ],
    }
},
methods:{
    //////// REDIRECT TABLE TO USER PROFILE

     redirectUserProfile(item) {
            localStorage.setItem('_@$wsar15+0-', JSON.stringify(item))
           this.$router.push({
                name: 'userProfile',
                params: {
                    item
                }
            })
        },

//////// REDIRECT TABLE TO MENU ASSIGN
          redirectMenuAssign(item) {
            localStorage.setItem('_@$wsar15+0-', JSON.stringify(item))
           this.$router.push({
                name: 'menuAssign',
                params: {
                    item
                }
            })
        },
}
}
</script>

<style>

</style>