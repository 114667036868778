<template>
    <div class="home">
        <div class="row">
        <div class="col-md-12" style="margin-top:70px !important; height:70px !important; padding:30px 0 0px 10px; position:fixed; z-index: 1; background:#fff; border-bottom: solid 1px #ccc; padding-left:40px">
            <span style="color:#777; font-size:18px;padding:5px;">Departments</span>/<span style="padding:5px; color:#555">Department list</span>
        </div>
    </div>
    
    <div style="background: #f5f5f5; margin-top:180px" class=" container-fluid">
        <!-- <div class="row" style="margin-bottom:20px">
            <div class="col-md-8" style="font-size:18px; padding-left: 20px;">All Department List </div>
            <v-text-field v-model="search" append-icon="mdi-magnify" label="Search Departments" single-line hide-details></v-text-field>

        </div> -->
        
        <v-row style="margin:0 !important; padding:0 !important">
    <v-col cols="12" style="">
        <div class="row">
            <div class="col-md-8 "> </div>
            <div class="col-md-4 mb-8">
                <v-text-field v-model="search" append-icon="mdi-magnify" label="Search Department List" single-line hide-details></v-text-field>
            </div>
        </div>
        <v-data-table :headers="headers" :items="desserts" :search="search" sort-by="calories" class="elevation-1">
            <template v-slot:top>
                <v-toolbar flat>
                    <!-- <v-toolbar-title>List Program</v-toolbar-title> -->
                    <!-- <v-divider class="mx-4" inset vertical></v-divider> -->
                    <v-spacer></v-spacer>
                    <v-row>
                        <v-spacer></v-spacer>

                        <!-- <v-col cols="10" sm="10" md="10">
                            <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line
                                hide-details class="mr-10"></v-text-field>
                        </v-col> -->
                        <v-col cols="4" sm="4" md="4">
                            <v-dialog v-model="dialog" persistent transition="" max-width="500px">
                                <template v-slot:activator="{ on, attrs }">
                                    <button v-bind="attrs" v-on="on" class="createInsideTableButton">
                                       <v-icon size="30" color="success">mdi-plus-circle</v-icon> Create Department
                                    </button>
                                </template>
                                <v-card>
                                    <v-card-title style="color:#666; font-size:18px">
                                        Create Department
                                        <v-spacer></v-spacer>
                                        <v-icon @click="dialog=fase">mdi-close-circle</v-icon>

        </v-card-title>      
        <v-divider style="margin-top:0px"></v-divider>
                      
        <v-card-text>
                                            <v-row>
                                                <div class="col-md-12 pa-8">
                                                    <v-select v-model="faculty" :items="items"
                                                        :rules="[v => !!v || 'Item is required']" label="Faculty"
                                                        required outlined></v-select>
                                                    <v-text-field v-model="department_name" label="Department Name" outlined>
                                                    </v-text-field>
                                                    <v-text-field v-model="department_code" label="Department Code" outlined>
                                                    </v-text-field>
                                                </div>
                                                
                                            </v-row>
                                            <v-card-actions>
                                        
                                        <v-spacer></v-spacer>

                                        <v-btn color="#c0e7fe" @click="save" :disabled="staffId==''" class="col-md-5 ma-auto" >Create Department</v-btn>

                                        <!-- <button class="next_button" @click="save">
                                            Add
                                        </button> -->
                                    </v-card-actions>
                                    </v-card-text>

                                    
                                </v-card>
                            </v-dialog>

                            <!-------- EDIT DEPARTMENT-->
                            <v-dialog v-model="dialogEdit" persistent transition=""
                                max-width="500px">
                                <v-card class="pb-3">
                                    <v-card-title style="color:#666; font-size:18px">
                                        Edit Department
                                        <v-spacer></v-spacer>
                                        <v-icon @click="dialogEdit=false">mdi-close-circle</v-icon>
        </v-card-title>
        <v-divider style="margin-top:0px"></v-divider>
                                    <v-card-text>
                                            <v-row>
                                                <div class="col-md-12">
                                                    <v-select v-model="faculty" :items="items"
                                                        :rules="[v => !!v || 'Item is required']" label="Faculty"
                                                        required outlined></v-select>
                                                    <v-text-field v-model="edepartment_name" label="Department Name" outlined>
                                                    </v-text-field>
                                                    
                                                    <v-text-field v-model="edepartment_code" label="Department Code" outlined>
                                                    </v-text-field>
                                                    
                                                    <v-text-field v-model="edepartment_slug" label="Slug" outlined>
                                                    </v-text-field>
                                                </div>
                                            </v-row>
                                            <v-card-actions >
                    <v-spacer></v-spacer>
         
          <v-btn color="#c0e7fe" @click="editDepartment" :disabled="staffId==''" class="col-md-5 ma-auto" >Save</v-btn>
               
                </v-card-actions>
                                    </v-card-text>
                                    
                                    
                                
                                </v-card>
                            </v-dialog>
<!--------------------------------------------------------->


                        </v-col>
                    </v-row>
                    <v-dialog v-model="dialogDelete" max-width="700px">
                        <v-card>
                            <v-card-title style="color:#666; font-size:18px">
                                        Delete Department
        </v-card-title>
        <v-divider style="margin-top:0px"></v-divider>
                           <v-card-text>
<v-alert type="warning">
You cannot delete a department with active student(s). You have to migrate the students to another department using the student migration menu if you wish to delete department.
</v-alert>
<div>
    <p style="font-size:16px">To delete this department, type the name of the department bellow. <br><span style="color:red; font-weight:600">Note that this action is not reversible</span></p>
    <v-text-field v-model="deleteDepartment" label="Department Name" outlined class="col-md-6" @copy.prevent @paste.prevent>
    </v-text-field>
</div>
                           </v-card-text>

                            <v-card-actions style="margin-top:-20px; padding-bottom:20px">                                
                                <v-btn color="error" @click="deleteItemConfirm" :disabled="deleteDepartment==''" class="col-md-5">Delete</v-btn>
                                <v-spacer></v-spacer>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-toolbar>
            </template>
            <template v-slot:[`item.sn`]="{ item }">
                        <tr>
                            <td class="table-txt">{{desserts.map(function(x) {return x.department; }).indexOf(item.department)+1}}</td>
                        </tr>
                    </template>
            <template v-slot:item.actions="{ item }">
                    <v-menu offset-y>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn color="" text v-bind="attrs" v-on="on">
                                <v-icon>mdi-dots-vertical</v-icon>
                            </v-btn>
                        </template>
                        <v-list>
                            <v-list-item @click="setupPrograDegree(item)">
                                <v-list-item-title><v-icon small class="mr-2">
                                    mdi-clipboard-arrow-left
                                </v-icon>Setup Degree</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="editItem(item)"> 
                                <v-list-item-title><v-icon small class="mr-2">
                                    mdi-pencil
                                </v-icon>Edit</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="setup(item)">
                                <v-list-item-title><v-icon class="mr-2" small>
                                    mdi-cogs
                                </v-icon>Setting</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="deleteItem(item)">
                                <v-list-item-title><v-icon class="mr-2" small>
                                    mdi-delete
                                </v-icon> Delete</v-list-item-title>
                            </v-list-item>
                            
                        </v-list>
                    </v-menu>               
            </template>
        </v-data-table>
</v-col>
</v-row>

        <v-dialog v-model="dialogSetupProgram" persistent transition="" max-width="500px">
            <v-card style="overflow: hidden">
                <v-card-title style="color:#666; font-size:18px">
                    Setup Program For {{dep.department}}
                        </v-card-title>
        <v-divider style="margin-top:0px"></v-divider>
                <v-card-text>
                    <v-container>
                        <div v-for="(proDeg, i) in programDegree" :key="i">
                            <div v-if="proDeg.degrees.length">
                                <v-checkbox :label="proDeg.programName" @click="proDeg.active =  !proDeg.active" v-model="proDeg.checked" >
                                </v-checkbox>
                                <div style="margin-left:50px">
                                <v-combobox multiple v-if="proDeg.active" outlined :items="proDeg.degrees"
                                    v-model="degrees" label="Select Degree">
                                </v-combobox>
                            </div>
                            </div>
                        </div>
                    </v-container>
                </v-card-text>



                <v-card-actions >
                    
                    <v-spacer></v-spacer>
          <v-btn color="#c0e7fe" @click="setupProgram" :disabled="staffId==''" class="col-md-5 ma-auto" >Add Program/Degree</v-btn>
                </v-card-actions>
                                

<!-- 
                <v-card-actions style="">
                    <div style="text-align: center">

                        <v-row>
                            <v-col cols="6">
                                <v-btn block color="#ccc" class="" @click="dialogSetupProgram = false">
                                    Cancel
                                </v-btn>
                            </v-col>
                            <v-col cols="6">
                                <v-btn block class="" @click="setupProgram">
                                    Procceed
                                </v-btn>

                            </v-col>
                        </v-row>
                    </div>
                </v-card-actions> -->
            </v-card>
        </v-dialog>
        </div>

    
</div>
</template>
<script>

    /***
     * DEPARTMENT SETTINGS
     * Authors : Chuka, Kenechukwu and Ralph
     * 
     * NOTES: If there is no degree attached to a programm, you will not be able to add that program or degree to a department.
     * For department status, 1 = active, 0 deleted and 2 is deactivated
     * 
     * 
     * 
     * 
     * TO DO.. SHOW THE DEPARTMENT NAME ON THE MODAL FOR DEPARMENT DELETE
     */


    import axios from 'axios';
import Swal from 'sweetalert2';
import {allDepartments,allFaculties} from '@Appmart/utility'
export default {
data: () => ({
    deleteDepartment:'',
    undergraduate: false,
    faculty: '',
    createdon: '',
    search: '',
    dialogEdit: false,
    dialogSetupProgram: false,
    programDegree: [],
    degreeArr: [],
    degrees: [],
    dep:{},
    dialog: false,
    dialogDelete: false,
    programDegArr: [],
    headers: [{
            text: 'S/N',
            align: 'start',
            sortable: true,
            value: 'sn',

        },{
            text: 'Department Name',
            sortable: true,
            value: 'department',
        },
        {
            text: 'Faculty',
            value: 'faculty'
        },
      
        {
            text: 'Department Code',
            value: 'departmentcode'
        },
        {
            text: 'Program / Degree',
            value: 'program'
        },
        {
            text: 'Created On',
            value: 'createdon'
        },
        {
            text: 'Status',
            value: 'status'
        },
        {
            text: 'Actions',
            value: 'actions',
            sortable: false
        },
    ],
    settingArr: [],
    select: {},
    item: {},
    items: [],
    desserts: [],
    editedIndex: -1,
    edepartment_name: '',
    edepartment_code: '',
    department_name: '',
    department_code: '',
    department_slug: '',
    edepartment_slug: '',
    defaultItem: {
        department: '',
        faculty: 0,
        departmentcode: '',
        createdon: 0,
        status: 0,
    },
}),

computed: {
    formTitle() {
        return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
    },
},

watch: {
    dialog(val) {
        val || this.close()
    },
    dialogDelete(val) {
        val || this.closeDelete()
    },
},

created() {
    this.fetchFaculties();
    // this.fetchDepartments();
    this.getAllDepartmentalSettings();
    this.fetchProDeg();
//     if (!checkifMenuIsBuilt) {
//         const myArrayFiltered = element.sub_menu.filter((el) => {
//             return this.neatAssigned.some((f) => {
//                 return f.submenu == el._id;
//             });
//         });
//         element.sub_menu = myArrayFiltered
//         myoperationalMenus.push(element)
//     }
},

methods: {

    editItem(item) {
        this.item = item;
        this.editedIndex = this.desserts.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogEdit = true;
        const faculty = this.items.find(el => el.text == item.faculty);
        this.edepartment_name = item.department;
        this.edepartment_code = item.departmentcode;
        this.edepartment_slug = item.slug;
        this.faculty = faculty.value;
    },
    async fetchProDeg() {
        const url = `${this.$appUrl}/getProgramDegree`
        try {
            const res = await axios.get(url);
            console.log('Here', res);
            if (res.data.code === 's200') {
                this.programDegree = res.data.message;
                // this.fetchDepartments();
            }
        } catch (error) {
            console.log(error);
        }
    },
    setupPrograDegree(item) {
        /////Find department setting from settsings array
        const setting = this.settingArr.find(el => el.departmentId === item.id);
        // console.log('Settings',setting);

        //Check if department has setting
        if(setting){
            //check for program that's been setup already
            setting.settings.forEach(setting => {
                const progDeg = this.programDegree.find(el => el.programId == setting.programId)
                progDeg.checked= true;
                progDeg.active = true;

                //Filter degree that was setup
                this.degrees = setting.degrees.map(el => {
                    const deg = progDeg.degrees.find(degg => degg.value.degreeId == el.id);

                    return deg;
                })

            });
        }else{
            //Set everything to default
            this.programDegree.forEach(el => {
                this.degrees = [];
                el.active = false;
                el.checked = false;
            })
        }
        this.dep = item;
        this.dialogSetupProgram = true;
    },
    deleteItem(item) {
        this.item = item;
        this.editedIndex = this.desserts.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
    },

    async deleteItemConfirm() {
        try {
            const url = `${this.$appUrl}/disableDepartment`

            const res = await axios.post(url, {
                id: this.item.id
            });

            if (res.data.code == '00') {
                this.desserts.splice(this.editedIndex, 1);
                this.closeDelete();
                Swal.fire({
                    icon: 'success',
                    text: res.data.message,
                    title: 'Successful'
                })
            } else {
                Swal.fire({
                    icon: 'error',
                    text: res.data.message,
                    title: 'Oops..'
                })
            }
        } catch (error) {
            console.log(error);
        }
    },

    close() {
        this.dialog = false
        this.$nextTick(() => {
            this.editedItem = Object.assign({}, this.defaultItem)
            this.editedIndex = -1
        })
    },
    async editDepartment() {
        try {
            if (!this.edepartment_name || !this.edepartment_code || !this.edepartment_slug || !this.faculty) {
                Swal.fire({
                    icon: 'error',
                    width: 450,
                    title: 'Empty Field',
                    text: 'Make sure all fields are filled',
                })
            } else {

                const data = {
                    name: this.edepartment_name,
                    code: this.edepartment_code,
                    id: this.item.id,
                    slug: this.edepartment_slug,
                    faculty: this.faculty
                }
                const url = `${this.$appUrl}/editDepartment`
                const res = await axios.post(url, data);

                if (res.data.code === '00') {
                    const faculty = this.items.find(el => el.value == this.faculty);
                    const index = this.desserts.indexOf(this.item);
                    this.desserts[index].department = this.edepartment_name;
                    this.desserts[index].faculty = faculty.text;
                    this.desserts[index].departmentcode = this.edepartment_code;
                    this.desserts[index].slug = this.edepartment_slug;

                    this.edepartment_name = '';
                    this.edepartment_code = '';
                    this.edepartment_slug = '';

                    this.dialogEdit = false;

                    return Swal.fire({
                        icon: 'success',
                        text: res.data.message,
                        title: 'Success'
                    });

                } else {
                    Swal.fire({
                        icon: 'error',
                        text: res.data.message,
                        title: 'Oops..'
                    })
                }

            }

        } catch (error) {
            console.log(error);
        }
    },
    closeDelete() {
        this.dialogDelete = false
        this.$nextTick(() => {
            this.editedItem = Object.assign({}, this.defaultItem)
            this.editedIndex = -1
        })
    },

    async setupProgram(){
        try {
            const activeProgram = this.programDegree.filter(el => el.active);
            // console.log(activeProgram);

            const settings = activeProgram.map(el => {
                return {
                    programId: el.programId,
                    programName: el.programName,
                    degrees: this.degrees.map(deg => {
                        if (deg.value.programId === el.programId){
                            return {
                                id: deg.value.degreeId,
                                name: deg.value.degreeName
                            }
                        }
                    })
                }
            })


            const data = {
                departmentId: this.dep.id,
                settings
            }
            const url = `${this.$appUrl}/departmentSetup`
            const res = await axios.post(url, data);
            if(res.data.code == 's200'){
                Swal.fire({icon: 'success', text: 'Program successfully setup', titel: 'Successful'});
                this.getAllDepartmentalSettings();
                this.dep = {};
                this.dialogSetupProgram = false;
                this.degrees = [];
            }else{
                Swal.fire({icon: 'error', text: res.data.message, title: 'Oops...'})
            }
            
            // console.log(data);
        } catch (error) {
            console.log(error);
        }
    },

    async getAllDepartmentalSettings(){
        try {
            const url = `${this.$appUrl}/getAllDepartmentalSettings`
            const res = await axios.get(url);

            if(res.data.code == 's200'){
                console.log('Here dth',res);
                this.settingArr = res.data.message;
                this.fetchDepartments()
            }
        } catch (error) {
            console.log(error);
        }
    },

    async fetchFaculties() {
        try {
            const activeFaculties = await allFaculties().then(activeSet => {
  return activeSet;
}).catch(e => {
  console.log(e);
})
            // const url = `${this.$appUrl}/fetchallfaculty`
            // const res = await axios.get(url);
            console.log(activeFaculties.data.code );
            if (activeFaculties.data.code == '00') {
                this.items = activeFaculties.data.message.map(el => {
                    return {
                        text: el.name,
                        value: el._id
                    }
                });
            }
            console.log(this.items)
        } catch (error) {
            console.log(error);
        }
    },

    async fetchDepartments() {
        try {

            const activeDepartments = await allDepartments().then(activeSet => {
  return activeSet;
}).catch(e => {
  console.log(e);
})


            if (activeDepartments.data.code === '00') {
                // console.log('Setting Arr',this.settingArr);
                this.desserts = activeDepartments.data.message.map(el => {
                    
                    const proDegrees = this.settingArr.find(sett => sett.departmentId == el._id);

                    console.log(proDegrees);
                    
                    const obj=  {
                        department: el.name,
                        faculty: el.faculty.name,
                        departmentcode: el.code,
                        slug: el.slug,
                        settings: el.settings,
                        id: el._id,
                        createdon: el.dateCreated.split('T')[0],
                        status: el.status === 1 ? 'Active' : 'Not Active'
                    }

                    if (proDegrees) {
                        const degreeProName = proDegrees.settings.map(prode => {
                            return `${prode.programName} (${prode.degrees.map(degname => degname.name)}) | `
                        }) 
                        obj['program'] = degreeProName;
                    }

                    return obj;
                })
                console.log(this.desserts);
            }
            // const datas = res.data.data.map((el) => el);
            // // console.log(datas);
            // this.departmentsHolder = datas;
        } catch (error) {
            Swal.fire({
                title: "Oops..",
                text: "Something wrong! reload the page again",
                icon: "info",
            });
            console.log(error);
        }
    },

    async save() {
        if (!this.department_name) {
            Swal.fire({
                icon: 'error',
                width: 450,
                title: 'Empty Field',
                text: 'Provide Department Name',
            })
            return
        }

        if (!this.faculty) {
            Swal.fire({
                icon: 'error',
                width: 450,
                title: 'Empty Field',
                text: 'Select Faculty',
            })
            return
        }

            const data = {
                name: this.department_name,
                code: this.department_code,
                slug: this.department_slug,
                faculty: this.faculty
            }
            const url = `${this.$appUrl}/createdepartment`

            const res = await axios.post(url, data);

            if (res.data.code === 's200') {
                const el = res.data.data;
                const faculty = this.items.find(el => el.value === this.faculty);
                this.desserts.push({
                    department: el.name,
                    faculty: faculty.text,
                    departmentcode: el.code,
                    slug: el.slug,
                    id: el._id,
                    createdon: el.dateCreated.split('T')[0],
                    status: el.status === 1 ? 'Active' : 'Not Active'
                })

                this.department_name = ''
                this.department_code = '';
                this.department_slug = '';
                this.faculty = '';
                this.dialog = false;

                return Swal.fire({
                    icon: 'success',
                    text: res.data.message,
                    title: 'Successful'
                })
            } else {
                Swal.fire({
                    icon: 'error',
                    text: res.data.message,
                    title: 'Ooops..'
                })
            }
            // Also pushes when you create

            // setTimeout(() => {
            // setup ')
   // }, 5000);
     
    },

    setup(item) {
var transData = window.btoa(JSON.stringify(item))
        console.log(item);

        this.$router.push({
                name: 'departmentSetting',
                params: {
              id: transData
            }
            })

  }
}
}
</script>
<style>

</style>
