
<template>
  <div class="home">
    <div class="row">
        <div class="col-md-12" style="margin-top:70px !important; height:70px !important; padding:30px 0 0px 10px; position:fixed; z-index: 1; background:#fff; border-bottom: solid 1px #ccc; padding-left:40px">
            <span style="color:#777; font-size:18px;padding:5px;">Menu</span>/<span style="padding:5px; color:#555">Menu Assigned list</span>
        </div>
    </div>
    <div style="background: #f5f5f5; margin-top:180px" class=" container-fluid">

    <v-row style="margin:0 !important; padding:0 !important">
    <v-col cols="12" style="">
       

      <div class="row" style="margin:0; padding:0">
       <v-card class="col-md-12">
        <div>Search filter</div>
        <v-divider></v-divider>
        <div class="row">
       <div class="col-md-3 ">
         <v-autocomplete dense clearable outlined v-model="filter.program_id" :items="programs" label="Select Program"></v-autocomplete>
       </div>

       <div class="col-md-3 ">
         <v-autocomplete dense clearable outlined v-model="filter.sub_menu" :items="subMenus" label="Select Menu"></v-autocomplete>
       </div>

       <div class="col-md-4 ">
         <div>
           <v-btn color="#c0e7fe" @click="submit" :disabled="staffId==''" v-if="!action" class="col-md-7 ma-auto" >Search</v-btn>
           <v-btn color="primary" @click="submit" elevation='2' class="col-md-7 ma-auto"  style="background-color:#e0e0e0 !important;color:#444  !important;padding:10px;cursor: pointer !important; " disabled v-else> <v-progress-circular
     indeterminate
     color="#777" :size="25"
   ></v-progress-circular> &nbsp;Serching</v-btn>          </div>
       </div>
      </div>
      </v-card>
     </div>





        <div class="row"  style="margin:0; padding:0; margin-top:10px">
          <v-card class="col-md-12 pa-0">
            <div class="row">
            <div class="col-md-8" style="font-size:18px; padding-left: 20px;"> </div>
            <div class="col-md-4">
                <v-text-field v-model="search" append-icon="mdi-magnify" label="Search Menu Assigned" single-line hide-details></v-text-field>
            </div></div>

        <v-data-table :items="staffs" :search="search" :headers="headers" style="margin-top:15px">
            <template v-slot:[`item.sn`]="{ item }">
                        <tr>
                            <td class="table-txt">{{staffs.map(function(x) {return x.id; }).indexOf(item.id)+1}}</td>
                        </tr>
                    </template>
            <template v-slot:item.actions="{item}">
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="" text v-bind="attrs" v-on="on">
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item @click="viewMenus(item)">
                    <v-list-item-title>
                      <img style="height: 20px; width: 20px" src="../assets/icons8-external-link-48.png" alt="">
                      View Menu</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
            <template v-slot:item.assignedDept="{item}">
              <span>{{listMenus(item.assignedDept)}}</span>
            </template>
          </v-data-table>
        </v-card>
</div>
    </v-col>
</v-row>
</div>
</div>
</template>



<script>
import axios from 'axios';
import { allProgrames } from "@Appmart/utility";
// import Swal from 'sweetalert2';
export default {
  data() {
    return {
      filter: {
        skola_id: '',
        sub_menu: '',
        program_id: '',
      },action:false,
      search: '',
      selectedBooks: [],
      booksCourse: [],
      departments: [],
      regnumber: '',
      program: '',
      programs: [],
      session: '',
      level: '',
      department: '',
      faculty: '',
      course: '',
      data: [],
      courseInfo: {
        studentId: {},
        facultyId: {},
        departmentId: {},
        program: {}
      },
      headers: [{
          text: 'S/N',
          align: 'start',
                value: 'sn',
                sortable:false
        },{
          text: 'Staff ID',
          value: 'staffId'
        },
        {
          text: 'Title',
          value: 'title',
          sortable:false
        },
        {
          text: 'Fullname',
          value: 'name'
        },
        {
          text: "Email",
          value: 'email'
        },

        {
          text: 'Menus',
          value: 'subMenuName',
        },
        {
          text: 'Programe',
          value: 'programe',
        },
        {
          text: 'Departments',
          value: 'assignedDept',
        },
      ],
      staffs: [],
      menus: [],
      staff: {
        el: {},
        department: {},
        faculty: {}
      },
      subMenus: []
    }
  },
  beforeMount() {

    // this.fetchDepartments();
    this.fetchProgram();
    // this.fetchStaffMenus();
    this.fetchSubmenus();
  },
  methods: {

    submit() {
      this.action = true
      this.fetchStaffMenus(this.filter);
      console.log(this.filter);
    },

    listMenus(items){
    console.log(items);
      const menuNames = items.map(el => el.name);

      return menuNames.join(', ');
    },  

    reset(item) {
      this.courseInfo = item;
      this.dialogRegset = true;
      console.log(item);
    },

    close() {
      this.filter = {
        staff_id: '',
        sub_menu: '',
        program: '',
      }
    },

    async  fetchSubmenus(){
            try {
                const res = await axios.get(`${this.$appUrl}/fetch-submenus`);
                const datas = [];
                if(res.data.code == 's200'){
                    res.data.message.forEach(sub => {
                      datas.push(
                           sub.menu_name,
                        )
                    });

                    this.subMenus = datas;
                }
            } catch (error) {
                console.log(error);
            }
        },

    async fetchProgram() {
       
      const activeProgrames = await allProgrames()
        .then((activeProg) => {
          return activeProg;
        })
        .catch((e) => {
          console.log(e);
        });

        if(activeProgrames.data.code=='00'){
          activeProgrames.data.message.forEach(prog => {
                      this.programs.push({
                           text:prog.name,
                           value:prog._id
                      })
                    });
        }
        console.log(activeProgrames);
    },

   

    async fetchStaffMenus(payload = {}) {
      try {
        this.search=''
        const res = await axios.post(`${this.$appUrl}/fetch-staff-menus`, payload);

        if (res.data.code == 's200') {
          console.log(res);

        //  let staffs = res.data.message.staffs.map(el => {
        //     return {
        //       sid: el.staffid,
        //       sname: `${el.name} ${el.othernames} ${el.surname}`,
        //       prefix: el.prefix,
        //       faculty: el.faculty,
        //       adtype: el.admin_type,
        //       department: el.department,
        //       el,
        //       menus: res.data.message.menus.filter(men => men.staff_id == el.staffid).map(menu => menu.sub_menu).map(da => {
        //         return {
        //           menu: da.menu_name,
        //           assigned_dep: da.department_assigned.map(depname => depname.text),
        //         }
        //       })
        //     }
        //   });

          this.staffs = res.data.message.menus;
          this.action = false
        }
      } catch (error) {
        console.log(error);
        this.action = false
      }
    }

  }

}
</script>

<style>
    
    </style>
