<template>
    <div class="home">
        <div class="row">
        <div class="col-md-12" style="margin-top:70px !important; height:70px !important; padding:30px 0 0px 10px; position:fixed; z-index: 1; background:#fff; border-bottom: solid 1px #ccc; padding-left:40px">
            <span style="color:#777; font-size:18px;padding:5px;">Admin Users</span>/<span style="padding:5px; color:#555">Profile User</span>
        </div>
    </div>
    <div style="background: #f5f5f5; margin-top:150px; " class=" container-fluid row ">
        <div style="margin:auto; padding:20px; margin-top:100px" class="col-md-5" v-if="valid">
                <v-card elevation="3" height="300">
                    <v-card-title style="color:#666; font-size:18px">
                        Find Staff
                    </v-card-title>
                    <v-divider style="margin-top:0px"></v-divider>

                    <v-col cols="11" style="margin:auto;padding-top:45px" pa-4>
                        <v-text-field outlined label="Enter staff CIRMS ID / staff ID" v-model="staffId" placeholder="CIRMS ID / Staff ID"></v-text-field>
                        <v-card-actions>
                        <v-btn color="#c0e7fe" @click="submit" :disabled="staffId==''" v-if="!activity" class="col-md-7 ma-auto" >Find Staff</v-btn>
                        <v-btn color="primary" @click="submit" elevation='2' class="col-md-7 ma-auto"  style="background-color:#e0e0e0 !important;color:#444  !important;padding:10px;cursor: pointer !important; " disabled v-else> <v-progress-circular
      indeterminate
      color="#777" :size="25"
    ></v-progress-circular> &nbsp;Finding staff</v-btn>
                        </v-card-actions>
                    </v-col>
                </v-card>
            </div>
<!-- <div class="col-md-5" style="margin:auto; margin-top:100px" v-if="valid" >
    <v-card class="col-md-12" style="padding-left:0">
        <v-card-title style="padding:0">
            Search Admin
        </v-card-title>
<v-divider></v-divider>
<v-card-text>
    <v-text-field v-model="staffId" label="Enter staff cirmsID/staffId/" outlined></v-text-field>
                                                <v-btn medium color="#006794" dark class=" pa-3 elevation-10" @click="submit" type="submit">
                                                    Next
                                                </v-btn>
</v-card-text>
        </v-card>

</div> -->
<div class="col-md-5" style="margin:auto; margin-top:50px" v-else>
    <v-card elevation="3" >
                    <v-card-title class="text-h7" style="color:#666">
                    Staff Information
                    </v-card-title>
                    <v-divider style="margin-top:0px"></v-divider>
<v-card-text>
    <v-text-field v-model="surname" type="text" label="Surname" readonly outlined></v-text-field>
<v-text-field v-model="name" type="text" label="Name" readonly outlined></v-text-field>
<v-text-field v-model="othernames" type="text" label="Othernames" readonly outlined></v-text-field>
<v-text-field v-model="email" type="text" label="Email" readonly outlined></v-text-field>
<v-text-field v-model="staffid" label="staff id" readonly outlined></v-text-field>
<v-select :items="items" item-text="name" item-value="id" v-model="type" label="Select Role" outlined></v-select>

<!-- <v-select v-model="type" :items="items" label="Select Admin Type" required>
    <template slot="item" slot-scope="data" >
<v-list-tile-content>
<v-list-tile-title @click="getItemText(data.item.name)" v-html="data.item.name"></v-list-tile-title>
</v-list-tile-content>
</template>
</v-select> -->

<v-card-actions>
    <!-- <v-btn color="blue darken-1" text @click="close">
        Cancel
    </v-btn> -->
    <v-spacer></v-spacer>
    <v-btn color="#c0e7fe" @click="save"  v-if="!activity" class="col-md-6" >Profile Staff</v-btn>
    <v-btn color="primary" @click="submit" elevation='2' class="col-md-6"  style="background-color:#e0e0e0 !important;color:#444  !important;padding:10px;cursor: pointer !important; " disabled v-else> <v-progress-circular
      indeterminate
      color="#777" :size="25"
    ></v-progress-circular> &nbsp;Profiling Staff</v-btn>
    <!-- <v-btn color="blue darken-1" text @click="save" >
        Profile Admin
    </v-btn>
     -->
    <!-- <v-btn color="blue darken-1" text @click="save">
        Save
    </v-btn> -->

</v-card-actions>
    </v-card-text>
    </v-card>
    </div>

  
    </div>

<!-- Successfull confirmation dialog -->
    <v-dialog
      v-model="dialog"
      max-width="390"
    >
      <v-card>
        <v-card-title class="text-h5">
         User Profiling
        </v-card-title>

        <v-card-text>
         User with Staff no has been profiled Succesfully. Will you want to asign menu to this user?
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="green darken-1"
            text
            @click="dialog = false"
          >
            No
          </v-btn>

          <v-btn
            color="green darken-1"
            text
            @click="movetoMenu()"
          >
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    
</div>
</template>

<script>
import Swal from 'sweetalert2';
import axios from 'axios';

export default {
    // props: ['name'],
    data() {
        return {
            dialog:false,
            createdData:'',
            activity:false,
            email: '',
            surname: null,
            search: '',
            name: null,
            type: null,
            othernames: null,
            adminInfo: null,
            staffId: '',
            valid: true,
            items: [
                "Bussary",
                "Lecturer",
                "Faculty",
                "Admission",
                "Department",
                "Exams and record",
                "General/Supper Admin",
                "Student Affairs / welfare",
            ],
        }
    },
    methods: {

        /**
         * Navigate to assign menu after creating a new staff
         * Todo: will have to determine if the user has permision to assign menu before we can navigate. 
         * for now  success will navigate to it after dialog confirm
         * 
         *  */ 
        movetoMenu(){
           localStorage.setItem('_@$wsar15+0-', JSON.stringify(this.createdData))
           this.$router.push({
                name: 'menuAssign',
            })
        },

        async submit() {
            this.activity = true
            if (!this.staffId) {
                Swal.fire("Please Enter a Valid Email/StaffId ", "error");
            } else {
                try {
                    var url = `${this.$appUrl}/fetchExtrenalAdmin`
                    const res = await axios.post(url, {
                        staffId: this.staffId
                    });
                    //    console.log('RES',res);
                    if (res.data.code === '00') {
                        // console.log('DATAAAAAAAA', res.data.data);
                        // this.adminInfo = res.data.data;
                        // this.othernames = this.adminInfo.personal_details.othernames;
                        // this.surname = this.adminInfo.personal_details.surname;
                        // this.email = this.adminInfo.personal_details.email;
                        // this.valid = false;

                        console.log(res.data);
                        const response = res.data.message;
                        this.adminDatas = response;
                        const {
                            other_details,
                            personal_details
                        } = response;
                        const names = personal_details.othernames.split(' ');
                        this.surname = personal_details.surname;
                        this.name = names[0];
                        this.email = personal_details.email
                        this.othernames = names[1];
                        this.staffid = personal_details.user_id;
                        this.status = other_details.staff_status;
                        this.valid = false;
                    } else {
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops..',
                            text: res.data.message + ' And make sure the CIRMS ID entered is correct'
                        })
                    }
                    this.activity = false
                } catch (error) {
                    this.activity = false
                    console.log(error);
                }
            }
        },
        close(){
            this.valid = true;
            this.type = '';
            // this.staffid = '';
        },

        async save() {
            this.activity = true
            if (
                !this.surname ||
                !this.staffid ||
                !this.type || !this.email 
            ) {
                this.activity = false
                Swal.fire(
                    "Oops..",
                    "Make sure you entered all fields properly to Add New admin",
                    "error"
                );
                
            } else {
                const details = this.adminDatas;
                const data = {
                    name: `${details.personal_details.surname} ${details.personal_details.othernames}`,
                    surname: details.personal_details.surname,
                    othernames: details.personal_details.othernames,
                    email: this.email,
                    admin_type: this.type,
                    prefix: details.personal_details.prefix,
                    cadre: details.work_details.cadre,
                    sex: details.personal_details.sex,
                    teaching_status: details.other_details.teaching_status,
                    hod_dean_status: details.other_details.hod_dean_status,
                    facdir_code: details.other_details.facdir_code,
                    depunit_code: details.other_details.depunit_code,
                    cirms_id: this.staffid,
                    phone: details.personal_details.phone,
                    staffid: this.staffId,
                }
                var url = `${this.$appUrl}/createadmin`

                const res = await axios.post(url, data)
                // console.log(res);
                if (res.data.code === '00') {                 
                    this.surname = '';
                    this.othernames = '';
                    this.type = '';
                    this.staffId = '';
                    this.valid = false;
this.dialog = true
this.createdData = res.data.message
                    Swal.fire({
                        icon: 'success',
                        title: 'Successful',
                        text: 'Admin successfully created'
                    })

                    this.activity = false

                    this.close()

                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'User Profiling Failed',
                        text: res.data.message
                    })
                    this.activity = false
                    if (res.data.code === 'E01') { 
                        this.close();    
                    }
                }
            }
        },
    }

}
</script>

<style>

</style>
