<template>
  <div class="home">
    <div class="row">
        <div class="col-md-12" style="margin-top:70px !important; height:70px !important; padding:30px 0 0px 10px; position:fixed; z-index: 1; background:#fff; border-bottom: solid 1px #ccc; padding-left:40px">
            <span style="color:#777; font-size:18px;padding:5px;">Faculties</span>/<span style="padding:5px; color:#555">Faculty list</span>
        </div>
    </div>
    <div style="background: #f5f5f5; margin-top:180px" class=" container-fluid">
      <!-- <div class="row" style="margin-bottom: 20px">
        <div class="col-md-8" style="font-size: 18px; padding-left: 20px">
          All Faculty List
        </div>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search Admin User"
          single-line
          hide-details
        ></v-text-field>
      </div> -->
      <v-row style="margin:0 !important; padding:0 !important">
        <v-col cols="12" style="">
          <div class="row">
            <div class="col-md-8 "> </div>
            <div class="col-md-4 mb-8">
                <v-text-field v-model="search" append-icon="mdi-magnify" label="Search Faculty List" single-line hide-details></v-text-field>
            </div>
        </div>
      <v-data-table :headers="headers" :items="faculties">
        <template v-slot:top>
          <v-toolbar flat>
            <!-- <v-toolbar-title>List Program</v-toolbar-title> -->
            <!-- <v-divider class="mx-4" inset vertical></v-divider> -->
            <v-spacer></v-spacer>
            <v-row>
              <v-spacer></v-spacer>

              <!-- <v-col cols="10" sm="10" md="10">
                            <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line
                                hide-details class="mr-10"></v-text-field>
                        </v-col> -->
              <v-col cols="3" sm="3" md="3">
                <v-dialog
                  v-model="dialog"
                  persistent
                  transition=""
                  max-width="500px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <button v-bind="attrs" v-on="on" class="createInsideTableButton">
                      <v-icon size="30" color="success">mdi-plus-circle</v-icon> Create Faculty
                    </button>
                  </template>
                  <v-card>
                    <v-card-title style="color: #666; font-size: 18px">
                      Create Department
                    </v-card-title>
                    <v-divider style="margin-top: 0px"></v-divider>

                    <v-card-text>
                      <v-row>
                        <div class="col-md-12 pa-8">
                          <v-select
                            v-model="faculty"
                            :items="items"
                            :rules="[(v) => !!v || 'Item is required']"
                            label="Faculty"
                            required
                            outlined
                          ></v-select>
                          <v-text-field
                            v-model="department_name"
                            label="Department Name"
                            outlined
                          >
                          </v-text-field>
                          <v-text-field
                            v-model="department_code"
                            label="Department Code"
                            outlined
                          >
                          </v-text-field>
                          <v-text-field
                            v-model="department_slug"
                            label="Slug"
                            outlined
                          ></v-text-field>
                        </div>
                      </v-row>
                    </v-card-text>

                    <v-card-actions>
                      <button class="btn-close mr-4" @click="close">
                        Cancel
                      </button>
                      <v-spacer></v-spacer>

                      <v-btn
                        color="#c0e7fe"
                        @click="save"
                        :disabled="staffId == ''"
                        class="col-md-5 ma-auto"
                        >Create Department</v-btn
                      >

                      <!-- <button class="next_button" @click="save">
                                            Add
                                        </button> -->
                    </v-card-actions>
                  </v-card>
                </v-dialog>

                <!-------- EDIT DEPARTMENT-->
                <v-dialog
                  v-model="dialogEdit"
                  persistent
                  transition=""
                  max-width="500px"
                >
                  <v-card class="pb-3">
                    <v-card-title style="color: #666; font-size: 18px">
                      Edit Department
                    </v-card-title>
                    <v-divider style="margin-top: 0px"></v-divider>
                    <v-card-text>
                      <v-row>
                        <div class="col-md-12">
                          <v-select
                            v-model="faculty"
                            :items="items"
                            :rules="[(v) => !!v || 'Item is required']"
                            label="Faculty"
                            required
                            outlined
                          ></v-select>
                          <v-text-field
                            v-model="edepartment_name"
                            label="Department Name"
                            outlined
                          >
                          </v-text-field>

                          <v-text-field
                            v-model="edepartment_code"
                            label="Department Code"
                            outlined
                          >
                          </v-text-field>

                          <v-text-field
                            v-model="edepartment_slug"
                            label="Slug"
                            outlined
                          >
                          </v-text-field>
                        </div>
                      </v-row>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <!-- <v-btn
            color="blue darken-1"
            text
            @click="close"
          >
            Close
          </v-btn> -->
                      <v-btn
                        color="#c0e7fe"
                        @click="editDepartment"
                        :disabled="staffId == ''"
                        class="col-md-7 ma-auto"
                        >Edit Department</v-btn
                      >
                      <!-- <div class="col-md-6" style="text-align: right; margin:auto"> -->
                      <!-- <button @click="handleAssignDepartment" class="button-sub2" type="submit">Assign Department</button> -->
                      <!-- </div> -->
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <!--------------------------------------------------------->
              </v-col>
            </v-row>
            <v-dialog v-model="dialogDelete" max-width="700px">
              <v-card>
                <v-card-title style="color: #666; font-size: 18px">
                  Delete Department
                </v-card-title>
                <v-divider style="margin-top: 0px"></v-divider>
                <v-card-text>
                  <v-alert type="warning">
                    You cannot delete a department with active student(s). You
                    have to migrate the students to another department using the
                    student migration menu if you wish to delete department.
                  </v-alert>
                  <div>
                    <p style="font-size: 16px">
                      To delete this department, type the name of the department
                      bellow. <br /><span style="color: red; font-weight: 600"
                        >Note that this action is not reversible</span
                      >
                    </p>
                    <v-text-field
                      v-model="deleteDepartment"
                      label="Department Name"
                      outlined
                      class="col-md-6"
                      @copy.prevent
                      @paste.prevent
                    >
                    </v-text-field>
                  </div>
                </v-card-text>

                <v-card-actions style="margin-top: -20px; padding-bottom: 20px">
                  <v-btn
                    color="error"
                    @click="deleteItemConfirm"
                    :disabled="deleteDepartment == ''"
                    class="col-md-5"
                    >Delete</v-btn
                  >
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:[`item.sn`]="{ item }">
                        <tr>
                            <td class="table-txt">{{faculties.map(function(x) {return x.fname; }).indexOf(item.fname)+1}}</td>
                        </tr>
                    </template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip top color="default">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                small
                class="mr-2"
                @click="showEdit(item)"
                v-bind="attrs"
                v-on="on"
              >
                mdi-pencil
              </v-icon>
            </template>
            <span>Edit</span>
          </v-tooltip>
          <v-tooltip bottom color="default">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                small
                class="mr-2"
                @click="showDelete(item)"
                v-bind="attrs"
                v-on="on"
              >
                mdi-delete
              </v-icon>
            </template>
            <span>Delete</span>
          </v-tooltip>
          <!-- <v-tooltip bottom color="default">
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon small class="mr-2" @click="settings(item)" v-bind="attrs" v-on="on">
                                mdi-cog
                            </v-icon>
                        </template>
                        <span>Settng</span>
                    </v-tooltip> -->
        </template>
      </v-data-table>
      </v-col>
</v-row>

      <v-dialog v-model="showCreateModel" width="500">
        <v-card>
          <v-card-title style="background: #d6ecf5">
            <v-row>
              <v-col cols="2"></v-col>
              <v-col cols="8">
                <h4 class="header">Create Faculty</h4>
              </v-col>
            </v-row>
          </v-card-title>

          <v-card-text class="px-7">
            <v-form class="pa-7">
              <div>
                <label for=""><strong>Faculty Name</strong></label>
                <v-text-field
                  label="Enter Faculty Name"
                  outlined
                  v-model="faculty_name"
                ></v-text-field>
              </div>
              <div>
                <label for=""><strong>Fauclty Slug</strong></label>
                <v-text-field
                  label="Enter Faculty Slug"
                  outlined
                  v-model="faculty_slug"
                ></v-text-field>
              </div>
              <div>
                <label for=""><strong>Fauclty Code</strong></label>
                <v-text-field
                  label="Enter Faculty Code"
                  outlined
                  v-model="faculty_code"
                ></v-text-field>
              </div>
              <v-btn
                class="pa-6 mt-3"
                style="background-color: #006794; color: #fff"
                block
                @click="createFaculty"
                >Create</v-btn
              >
            </v-form>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-dialog v-model="showEditModal" width="500">
        <v-card>
          <v-card-title style="background: #d6ecf5">
            <v-row>
              <v-col cols="2"></v-col>
              <v-col cols="8">
                <h4 class="header">Edit Faculty</h4>
              </v-col>
            </v-row>
          </v-card-title>

          <v-card-text class="px-7">
            <v-form class="pa-7">
              <div>
                <label for=""><strong>Faculty Name</strong></label>
                <v-text-field
                  label="Enter Faculty Name"
                  outlined
                  v-model="efaculty_name"
                ></v-text-field>
              </div>
              <div>
                <label for=""><strong>Fauclty Slug</strong></label>
                <v-text-field
                  label="Enter Faculty Slug"
                  outlined
                  v-model="efaculty_slug"
                ></v-text-field>
              </div>
              <div>
                <label for=""><strong>Fauclty Code</strong></label>
                <v-text-field
                  label="Enter Faculty Code"
                  outlined
                  v-model="efaculty_code"
                ></v-text-field>
              </div>
              <v-btn
                class="pa-6 mt-3"
                style="background-color: #006794; color: #fff"
                block
                @click="editItem"
                >Edit</v-btn
              >
            </v-form>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogDelete" width="500">
        <v-card>
          <v-card-title style="background: #d6ecf5">
            <v-row>
              <v-col cols="2"></v-col>
              <v-col cols="8">
                <h4 class="header">Delete Faculty</h4>
              </v-col>
            </v-row>
          </v-card-title>

          <div class="pa-5">
            <h4 class="text-center">
              Are you sure you want to delete this faculty
            </h4>

            <div class="text-center">
              <v-btn
                class="pa-6 mt-3"
                style="background-color: #006794; width: 200px; color: #fff"
                @click="dialogDelete = false"
                >Cancel</v-btn
              >
              <v-btn
                class="pa-6 mt-3 ml-3"
                style="background-color: #006734; width: 200px; color: #fff"
                @click="deleteItem"
                >Proceed</v-btn
              >
            </div>
          </div>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import { allDepartments, allFaculties } from "@Appmart/utility";

export default {
  data() {
    return {
      dialogDelete: false,
      showEditModal: false,
      efaculty_name: "",
      efaculty_slug: "",
      efaculty_code: "",
      item: null,
      faculty_name: "",
      faculty_slug: "",
      faculty_code: "",
      showCreateModel: false,
      faculties: [],
      // faculty slug, faculty code, created by, date created action
      headers: [
      {
          text: "S/N",
          value: "sn",
        },
        {
          text: "Faculty Name",
          value: "fname",
        },
        
        {
          text: "Faculty Slug",
          value: "fslug",
        },
        {
          text: "Faculty Code",
          value: "fcode",
        },
        {
          text: "Created By",
          value: "fcreated",
        },
        {
          text: "Date Created",
          value: "dcreated",
        },
        {
          text: "Action",
          value: "actions",
        },
      ],
    };
  },
  beforeMount() {
    this.fetchFaculties();
  },
  methods: {
    async deleteItem() {
      try {
        const res = await axios.post("http://localhost:5000/deletefaculty", {
          id: this.item.fid,
        });

        if (res.data.code === "s200") {
          this.faculties.splice(this.faculties.indexOf(this.item), 1);
          this.dialogDelete = false;
          Swal.fire({
            icon: "success",
            title: "Successful",
            text: res.data.message,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops..",
            text: res.data.message,
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    showEdit(item) {
      this.efaculty_name = item.fname;
      this.efaculty_slug = item.fslug;
      this.efaculty_code = item.fcode;
      this.showEditModal = true;
      this.item = item;
      console.log("Index", this.faculties.indexOf(this.item));
    },
    async editItem() {
      try {
        if (!this.efaculty_name || !this.efaculty_slug || !this.efaculty_code)
          return Swal.fire({
            icon: "info",
            text: "Please make sure your fill in all fields correctly",
            title: "Oops..",
          });

        const data = {
          name: this.efaculty_name,
          code: this.efaculty_code,
          slug: this.efaculty_slug,
          id: this.item.fid,
        };

        const res = await axios.post(
          "http://localhost:5000/updatefaculty",
          data
        );

        if (res.data.code == "s200") {
          this.faculties[this.faculties.indexOf(this.item)].fname =
            this.efaculty_name;
          this.faculties[this.faculties.indexOf(this.item)].fcode =
            this.efaculty_code;
          this.faculties[this.faculties.indexOf(this.item)].fslug =
            this.efaculty_slug;

          this.efaculty_name = "";
          this.efaculty_slug = "";
          this.efaculty_code = "";
          this.showEditModal = false;

          return Swal.fire({
            icon: "success",
            text: res.data.message,
            title: "Successful",
          });
        } else {
          Swal.fire({ icon: "error", text: res.data.message, title: "Oops.." });
        }
      } catch (error) {
        console.log(error);
      }
    },
    openCreateModal() {
      this.showCreateModel = true;
      const code = Math.floor(1000 + Math.random() * 9000);
      this.faculty_code = code;
    },
    showDelete(item) {
      this.item = item;
      this.dialogDelete = true;
    },
    settings(item) {
      console.log(item);
      this.$router.push({ name: "FacultySettings", params: { id: item.fid } });
    },
    async createFaculty() {
      try {
        if (!this.faculty_name || !this.faculty_slug || !this.faculty_code)
          return Swal.fire({
            icon: "info",
            title: "Oops..",
            text: "Please complete the form",
          });
        // const slug = this.faculty_slug.split(' ').join('_').toLowerCase();

        const data = {
          slug: this.faculty_slug,
          name: this.faculty_name,
          code: this.faculty_code,
          createdBy: "skolarsuite",
        };

        const res = await axios.post(
          "http://127.0.0.1:5000/createfaculty",
          data
        );
        if (res.data.code === "s200") {
          this.showCreateModel = false;
          this.faculty_name = "";
          this.faculty_slug = "";
          this.faculty_code = "";
          const el = res.data.data;
          this.faculties.push({
            fname: el.name,
            fslug: el.slug,
            fid: el._id,
            fcode: el.code,
            fcreated: el.createdBy,
            dcreated: el.dateCreated.split("T")[0],
          });

          Swal.fire({
            icon: "success",
            text: res.data.message,
            title: "Successful",
          });
        } else {
          Swal.fire({
            icon: "error",
            text: res.data.message,
            title: "Oops..",
          });
        }
        console.log(res);
      } catch (error) {
        console.log(error);
      }
    },

    async fetchFaculties() {
      try {
        const activeFaculties = await allFaculties()
          .then((activeSet) => {
            return activeSet;
          })
          .catch((e) => {
            console.log(e);
          });

        if (activeFaculties.data.code == "00") {
          console.log(activeFaculties);
          activeFaculties.data.message.forEach((el) => {
            this.faculties.push({
              fname: el.name,
              // fslug: el.slug,
              fid: el._id,
              fcode: el.code,
              fcreated: el.createdBy,
              dcreated: el.dateCreated.split("T")[0],
            });
          });

          console.log(this.faculties);
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style>
</style>