<template>
<div class="home">
    <div class="row">
        <div class="col-md-12" style="margin-top:70px !important; height:70px !important; padding:30px 0 0px 10px; position:fixed; z-index: 1; background:#fff; border-bottom: solid 1px #ccc; padding-left:40px">
            <span style="color:#777; font-size:18px;padding:5px;">Assign Menu</span>/<span style="padding:5px; color:#555">User list</span>
        </div>
    </div>
     <div style="background: #f5f5f5; margin-top:180px" class=" container-fluid">
<v-row style="margin:0 !important; padding:0 !important">
    <v-col cols="12" style="">
        <div class="row">
            <!-- <div class="col-md-10" style="font-size:20px; padding:20px 0 0 20px">Admin User </div> -->
            <!-- <div class="col-md-2">
                <v-text-field v-model="search" append-icon="mdi-magnify" label="Search Admin User" single-line hide-details></v-text-field>
            </div> -->
        </div>
        <tableList :dataToshow="desserts" :table="'assignMenu'"></tableList>
              
    </v-col>
</v-row>
</div>
</div>
</template>

<script>
import axios from 'axios';
import Swal from 'sweetalert2';
import tableList from './tableList.vue'
export default {
    components:{
        tableList
    },
    data: () => ({
        search: '',
        surname: '',
        names: '',
        othernames: '',
        staffid: '',
        status: '',
        dialog: false,
        valid: false,
        valid1: true,
        type: '',
        dialogDelete: false,
        email: '',
        adminDatas: null,
        headers: [{
                text: 'S/N',
                align: 'start',
                value: 'sn',
            },
            {
                text: 'Staff id',
                value: 'skola_id'
            },
            {
                text: 'Tittle',
                value: 'prefix',
            },
            {
                text: 'Surname',
                value: 'surname',
            },
            {
                text: 'Name',
                value: 'name'
            },

            {
                text: 'OtherNames',
                value: 'othernames'
            },
            {
                text: 'Email',
                value: 'email'
            },
            {
                text: 'Date Profiled',
                value: 'email'
            },
            {
                text: 'Profiled By',
                value: 'email'
            },

            {
                text: 'Type',
                value: 'admin_type_name'
            },
            {
                text: 'Status',
                value: 'status'
            },
            {
                text: '',
                value: 'actions',
                sortable: false
            },
        ],
        items: [{
                "name": 'Admission Officer',
                "id": 100
            },
            {
                "name": 'Bussary Officer',
                "id": 200
            },
            {
                "name": 'Department Officer',
                "id": 300
            },
            {
                "name": 'Exams and record Officer',
                "id": 400
            },
            {
                "name": 'Faculty Officer',
                "id": 500
            },
            {
                "name": 'General Admin',
                "id": 1000
            },
            {
                "name": 'Lecturer',
                "id": 700
            },
            {
                "name": 'Student Affairs Officer',
                "id": 800
            }
        ],
        desserts: [],
        editedIndex: -1,
        editedItem: {
            surname: '',
            names: '',
            othernames: '',
            staffid: '',
            type: '',
            status: '',
        },

    }),

    watch: {
        dialog(val) {
            val || this.close()
        },
        dialogDelete(val) {
            val || this.closeDelete()
        },
    },

mounted(){
console.log('chuka')
this.desserts =  this.$router.currentRoute.params.response.message
console.log(this.$router.currentRoute.params);
},
 // this.$router.push(`/settings?id=${item.staffid}`);
    methods: {
        redirect(item) {
            localStorage.setItem('_@$wsar15+0-', JSON.stringify(item))
           this.$router.push({
                name: 'MenuAssign',
                params: {
                    item
                }
            })
        },
        show() {
            this.valid = true
        },
        show1() {
            this.valid1 = false
        },

        editItem(item) {
            this.editedIndex = this.desserts.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.dialog = true
        },

        deleteItem(item) {
            this.editedIndex = this.desserts.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.dialogDelete = true
        },

        deleteItemConfirm() {
            this.desserts.splice(this.editedIndex, 1)
            this.closeDelete()
        },

        close() {

            this.dialog = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },

        closeDelete() {
            this.dialogDelete = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },
        searchAdmin() {
            this.dialog = true;
            this.valid = false;
            this.valid1 = true;
        },
        async submit() {
            if (!this.email) {
                Swal.fire({
                            text: 'Please Enter a Valid Email/StaffId',
                            title: 'Oops..',
                            icon: 'info'
                        })

            } else {
                const url = $appUrl+'/fetchExtrenalAdmin';
                await axios.post(url, {
                    staffId: this.email
                }).then(res => {
                    if (res.data.code == '00') {
                        console.log(res.data);
                        const response = res.data.message;
                        this.adminDatas = response;
                        const {
                            other_details,
                            personal_details
                        } = response;
                        const names = personal_details.othernames.split(' ');
                        this.surname = personal_details.surname;
                        this.name = names[0];
                        this.othernames = names[1];
                        this.staffid = personal_details.user_id;
                        this.status = other_details.staff_status;
                        this.valid = true;
                        this.valid1 = false;
                    } else {
                        return Swal.fire({
                            text: res.data.message,
                            title: 'Oops..',
                            icon: 'error'
                        })
                    }
                }).catch(err => console.log(err));

            }
        },

        save() {
            if (!this.surname || !this.name  || !this.staffid || !this.type) {
                Swal.fire('Oops..', 'Make sure you entered all fields properly to Add New admin', 'info');
                this.email = '';
            } else {
                const {
                    personal_details,
                    other_details,
                    work_details
                } = this.adminDatas;

                console.log(this.type);
                const data = {
                    name: this.name,
                    surname: this.surname,
                    othernames: this.othernames,
                    email: personal_details.email,
                    staffid: work_details.staff_no,
                    status: this.status,
                    admin_type: this.type,
                    prefix: personal_details.prefix,
                    sex: personal_details.sex,
                    cadre: work_details.cadre,
                    teaching_status: other_details.teaching_status,
                    hod_dean_status: other_details.hod_dean_status,
                    facdir_code: other_details.facdir_code,
                    depunit_code: other_details.depunit_code,
                    cirms_id: personal_details.user_id,
                    phone: personal_details.phone,
                }
                const url = $appUrl+'/createadmin';
                axios.post(url, data).then(res => {
                    if (res.data.code == '00') {
                        data.skola_id = data.cirms_id
                        this.desserts.push(data)
                        this.valid = false;
                        this.dialog = false;
                        Swal.fire({
                            icon: 'success',
                            text: res.data.message,
                            title: 'Successful',
                        });
                    } else {
                        this.valid1 = true;
                        this.valid = false;
                        return Swal.fire({
                            icon: 'error',
                            text: res.data.message,
                            title: 'Error'
                        });

                    }

                }).catch(() => {
                    return Swal.fire({
                        icon: 'error',
                        text: 'Something went wrong',
                        title: 'Error'
                    })
                })
                this.email = '';
                this.type = '';
            }
        },
    },

}
</script>

<style scoped>
.v-row {
    margin: 0 !important;
    padding: 0 !important;
}
</style>
