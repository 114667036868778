import Vue from 'vue'
import VueRouter from 'vue-router'
import axios from 'axios'
import users from '../components/listCreateAdminList.vue'
import userProfile from '../components/userProfile.vue'
import menuAssign from '../components/menuAssign.vue'
import assignMenu from '../components/assignMenu.vue'
import profileAdmin  from "../components/profileAdmin.vue";
import  programes from "../components/programs.vue";
import  programSettings from "../components/programSettings.vue";
import  fees from "../components/fees/setupFee.vue";
import listMenuAssign from "../components/FetchMenus.vue";
///// department create and list import with degree assignment
import department from '../components/listDepartments.vue'
import postutemlist from '../components/postutmeFormList.vue'
import jambReportlist from '../components/JambReports.vue'
import degreeSetting from '../components/degreeSetting.vue'

/// faculty create and list import
import faculty from '../components/listFaculties.vue'



import {parseJwt,getCookie} from '@Appmart/utility'

//import allocatedRegnumb from '../components/'

// import {getLoggedInUser,getCookie,checkMenuEligibility,parseJwt} from '@Appmart/utility'

Vue.use(VueRouter)
const routes = [
  {
  path: '/settings/users',
  name: 'users',
  beforeEnter: async (to, from, next) => {
    // console.log(getCookie('-X_Zka'));
    var url = process.env.VUE_APP_SETTINGS_V1_API_ENDPOINT+"/listadmin";
   const getdata = await axios({
      method: 'GET',
      url: url,
     // headers: {"Authorization": `Bearer `+ getCookie('-X_Zka')}
  }).catch(e => {
    alert('Something went wrong')
    next(false)
  })
              if(getdata.data.code =='00'){
              console.log(getdata.data);
              to.params.response = getdata.data;
              next({ params: to.params });
              }
           
            
        },
  component: users
},
{
  path: '/settings/assignmenu',
  name: 'assignmenu',
  beforeEnter: async (to, from, next) => {
    // console.log(getCookie('-X_Zka'));
    var url = process.env.VUE_APP_SETTINGS_V1_API_ENDPOINT+"/listadmin";
   const getdata = await axios({
      method: 'GET',
      url: url,
     // headers: {"Authorization": `Bearer `+ getCookie('-X_Zka')}
  }).catch(e => {
    alert('Something went wrong')
    next(false)
  })
              if(getdata.data.code =='00'){
              console.log(getdata.data);
              to.params.response = getdata.data;
              next({ params: to.params });
              }
           
            
        },
  component: assignMenu
},


{

  path: '/settings/degreeSetup/:id',
  name: 'departmentSetting',
  component: degreeSetting
},
{

  path: '/settings/userprofile',
  name: 'userProfile',
  component: userProfile
},
{

  path: '/settings/menuassign',
  name: 'menuAssign',
  component: menuAssign
},
{

  path: '/settings/menuAssignedList',
  name: 'listMenuassign',
  component: listMenuAssign
},

////// list/ create department route
{

  path: '/settings/departments',
  name: 'departments',
  component: department
},


////// list / create faculty route

{

  path: '/settings/faculties',
  name: 'faculties',
  component: faculty
},
//// PROFILE ADMIN USER
{

  path: '/settings/addUser',
  name: 'profileAdmin',
  component: profileAdmin
},


////// list/ create department route
{

  path: '/settings/programes',
  name: 'programes',
  component: programes
}
,

////// program settings
{
  path:'/settings/programesetting/:id',
  name: 'ProgramSettings',
  component: programSettings
},
/**
 * FEES ROUTES
 */
 {
  path:'/settings/fees',
  name: 'listFees',
  component: fees
},




////////// POSTUTME REMOVE LATTER

{
  path:'/settings/putmereport',
  name: 'Post UTME Report',
  component: postutemlist
},

////////// POSTUTME REMOVE LATTER

{
  path:'/settings/jambreport',
  name: 'JAMB Report',
  component: jambReportlist
}

]


const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})



 router.beforeEach((to, from, next) => {
  console.log(to);  
  if(to.matched.some(record => record.meta.requiresAuth)) {
    let name = getCookie("-X_Zka");
    console.log(name);
    if(!name){
  window.location.href =`${window.location.origin}/#/auth`;
}else{
  if(to.matched.some(record1 => record1.meta.check_link)) {
    const jwtDecode = parseJwt(name)
    // check if user is eligible for the menu
  var url = process.env.VUE_APP_SETTINGS_V1_API_ENDPOINT+"/verifyMenu";
   axios.post(url,{
    staffid:jwtDecode.staffid,
    menuPath:to.fullPath
   })
                .then(response => {
                  if(response.data.code =='00'){
                    console.log(response.data.message);
                    if(response.data.message == true){
                      next()
                    }else{
                      window.location.href =`${window.location.origin}/#/auth`;
                    }
                  }else{
                    window.location.href =`${window.location.origin}/#/auth`;
                    
                  }
                })
                .catch(e => {
                  window.location.href =`${window.location.origin}/#/auth`;
                  alert('Something went wrong')
                })  
  }else{
    next()
  }
}
  }else{
    if(to.matched.some(record => record.meta.check_link)) {

      const jwtDecode = parseJwt(name)
      // check if user is eligible for the menu
    var url = process.env.VUE_APP_ADMISSIONS_V1_API_ENDPOINT+"/verifyMenu";
     axios.post(url,{
      staffid:jwtDecode.staffid,
      menuPath:to.fullPath
     })
                  .then(response => {
                    if(response.data.code =='00'){
                      console.log(response.data.message);
                      if(response.data.message == true){
                        next()
                      }else{
                        window.location.href =`${window.location.origin}/#/auth`;
                      }
                    }else{
                      window.location.href =`${window.location.origin}/#/auth`;
                      
                    }
                  })
                  .catch(e => {
                    window.location.href =`${window.location.origin}/#/auth`;
                    alert('Something went wrong')
                  })    }else{
    next()
    }
  }
  next()
})


export default router



function checkMenu(name){
  const jwtDecode = parseJwt(name)
  // check if user is eligible for the menu
  var url = process.env.VUE_APP_ADMISSIONS_V1_API_ENDPOINT+"/verifyMenu";
  axios.post(url,{
  staffid:jwtDecode.staffid,
  menuPath:to.fullPath
 })
              .then(response => {
                if(response.data.code =='00'){
                  console.log(response.data.message);
                  if(response.data.message == true){
                    next()
                  }else{
                    window.location.href =`${window.location.origin}/#/auth`;
                  }
                }else{
                  window.location.href =`${window.location.origin}/#/auth`;
                  
                }
              })
              .catch(e => {
                window.location.href =`${window.location.origin}/#/auth`;
                alert('Something went wrong')
              })
}
