<template>
  <div class="home">
    <div class="row">
        <div class="col-md-12 homeheader">
            <span style="color:#777; font-size:18px;padding:5px;">Programes</span>/<span style="padding:5px; color:#555">Program list</span>
        </div>
    </div>
    <div style="background: #f5f5f5; margin-top:180px" class=" container-fluid">
      <!-- <div class="row" style="margin-bottom: 20px">
        <div class="col-md-8" style="font-size: 18px; padding-left: 20px">
          All Program List
        </div>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search Admin User"
          single-line
          hide-details
        ></v-text-field>
      </div> -->
      <v-row style="margin:0 !important; padding:0 !important">
    <v-col cols="12" style="">
      <div class="row">
            <div class="col-md-8 "> </div>
            <div class="col-md-4 mb-8">
                <v-text-field v-model="search" append-icon="mdi-magnify" label="Search Programe List" single-line hide-details></v-text-field>
            </div>
        </div>
      <v-data-table
        :headers="headers"
        :items="programForms"
        :search="search"
        class="elevation-1"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-row>
              <!-- <v-col cols="10" sm="10" md="10">
                        <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details class="mr-10"></v-text-field>
                    </v-col> -->
              <v-spacer></v-spacer>
              <v-col cols="2" sm="2" md="2">
                <v-dialog
                  v-model="dialogProgram"
                  persistent
                  max-width="600"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <button class="createInsideTableButton" v-bind="attrs" v-on="on">
                      <v-icon size="30" color="success">mdi-plus-circle</v-icon>  Create Programe
                    </button>
                  </template>
                  <v-card>
                    <v-card-title style="color:#666; font-size:18px">
                           Create Programe
                           <v-spacer></v-spacer>
                           <v-icon @click="dialogProgram=false">mdi-close-circle</v-icon>
                        </v-card-title>
                        <v-divider style="margin-top:0px"></v-divider>
                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col cols="12" sm="12" md="12">
                            <v-text-field
                              v-model="program"
                              label="Program Name" outlined dense hint="* This field is compulsary" persistent-hint
                            ></v-text-field><br>
                            <v-text-field
                              v-model="code"
                              label="Program Code" outlined dense
                            ></v-text-field>
                          </v-col>
                         
                        </v-row>
                      </v-container>
                      <v-card-actions style="width: 60%">
                      <v-spacer></v-spacer>
                      <button class="btn-close mr-4" @click="close">
                        Cancel
                      </button>
                      <button class="next_button" @click="createProgram">
                        Add
                      </button>
                    </v-card-actions>
                    </v-card-text>
                  
                  </v-card>
                </v-dialog>
              </v-col>
            </v-row>
            <v-dialog
              v-model="dialogEdit"
              transition="dialog-top-transition"
              max-width="600"
            >
              <v-card>
                <v-toolbar color="#006794" dark>Edit Program</v-toolbar>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="12" md="12">
                        <v-text-field
                          v-model="eprogram"
                          label="Program Name"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6" sm="6" md="6">
                        <v-text-field
                          v-model="ecode"
                          label="Program Code"
                        ></v-text-field>
                      </v-col>
                     
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="error" text @click="dialogEdit = false">
                    Cancel
                  </v-btn>
                  <v-btn color="success" text @click="editprogram">
                    Edit
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="text-h5"
                  >Are you sure you want to delete this item?</v-card-title
                >
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeDelete"
                    >Cancel</v-btn
                  >
                  <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                    >OK</v-btn
                  >
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:[`item.sn`]="{ item }">
                        <tr>
                            <td class="table-txt">{{programForms.map(function(x) {return x._id; }).indexOf(item._id)+1}}</td>
                        </tr>
                    </template>
        <template v-slot:item.actions="{ item }">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="" text v-bind="attrs" v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="editItem(item)">
                <v-list-item-title
                  ><v-icon small class="mr-2"> mdi-pencil </v-icon
                  >Edit</v-list-item-title
                >
              </v-list-item>
              <v-list-item @click="deleteItem(item)">
                <v-list-item-title
                  ><v-icon small class="mr-2"> mdi-delete </v-icon
                  >Delete</v-list-item-title
                >
              </v-list-item>
              <v-list-item @click="openSettings(item)">
                <v-list-item-title
                  ><v-icon class="mr-2" small> mdi-cogs </v-icon
                  >Setting</v-list-item-title
                >
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
        <!-- <template v-slot:no-data>
            <v-btn color="primary" text @click="populateTable">
                Reset
            </v-btn>
        </template> -->
      </v-data-table>
      </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import { allProgrames } from "@Appmart/utility";
export default {
  data: () => ({
    isCreate: true,
    isEdit: false,
    eprogram: "",
    ecode: "",
    eslug: "",
    search: "",
    code: "",
    slug: "",
    program: "",
    dialog: false,
    dialogProgram: false,
    dialogEdit: false,
    dialogDelete: false,
    index: null,
    item: "",

    headers: [
    {
        text: "S/N",
        align: "start",
        sortable: true,
        value: "sn",
      },
      {
        text: "Program Name",
        align: "start",
        sortable: true,
        value: "name",
      },
      {
        text: "Program Code",
        value: "programId",
      },

      {
        text: "Date Created",
        value: "datecreated",
      },
      {
        text: "Created By",
        value: "createdby",
      },
      {
        text: "Actions",
        value: "actions",
        sortable: false,
      },
    ],
    programForms: [],
    editedIndex: -1,
    editedItem: {
      program: "",
      programcode: 0,
      status: 0,
      datecreated: 0,
      createdby: "",
    },
    defaultItem: {
      program: "",
      programcode: 0,
      status: 0,
      datecreated: 0,
      createdby: "",
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  mounted() {
    this.populateTable();
  },

  methods: {
    showProgramForm() {
      this.isCreate = true;
      this.isEdit = false;
      this.dialogProgram = true;
      this.program = "";
    },
    openSettings(item) {
      //localStorage.setItem("programId", JSON.stringify(item));
      console.log(item);
      const data = window.btoa(JSON.stringify(item))
      this.$router.push({
        path: `/settings/programesetting/${data}`,
       // params: { program: JSON.stringify(item) },
      });
    },
    async populateTable() {
      this.isCreate = true;
      this.isEdit = true;

      const activeProgrames = await allProgrames()
        .then((activeProg) => {
          return activeProg;
        })
        .catch((e) => {
          console.log(e);
        });
        activeProgrames.data.message.forEach(el => {
                this.programForms.push({
                  name: el.name,
                  programId: el.programId,
                  status: `${el.status === 1 ? 'Active' : 'Not Active'}`,
                  datecreated: el.dateCreated.split('T')[0],
                //   slug: el.slug,
                  fid: el._id,
                  createdby: el.createdBy
                })
              });
    },

    async createProgram() {
      if (!this.program) {
        return Swal.fire({
          icon: "info",
          title: "Oop..",
          text: "Please provide program name",
        });
      }
      const rand = Math.floor(1000 + Math.random() * 10000);
      const name = "Skolarsuite";
      const data = {
        program: this.program,
        name,
        slug: this.slug,
        code: this.code,
        createdby: "Skolarsuite",
      };
      if (!this.program) {
        Swal.fire("Oops..", "Please Enter Program", "info");
      } else {
        const url = `${this.$appUrl}/createprogram`;
        axios
          .post(url, data)
          .then((res) => {
            if (res.data.code == "00") {
              this.programForms.push({
                program: this.program,
                programcode: rand,
                createdby: name,
                datecreated: res.data.message.dateCreated.split("T")[0],
                slug: this.slug,
              });
              this.dialogProgram = false;
              this.program = "";
              Swal.fire(
                res.data.status,
                "program created successfully",
                "success"
              );
            } else {
              Swal.fire("Sorry", res.data.message, "error");
            }
          })
          .catch((error) => {
            Swal.fire("Sorry", "Something went wrong", "error");
            console.log(error);
          });
      }
    },

    deleteItem(item) {
      this.item = item;
      this.editedIndex = this.programForms.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    editItem(item) {
      this.item = item;
      this.ecode = item.programcode;
      this.eprogram = item.program;
      this.eslug = item.slug;
      this.dialogEdit = true;
    },

    async editprogram() {
      const item = this.item;
      console.log(item);
      const data = {
        slug: this.eslug,
        code: this.ecode,
        id: this.item.fid,
        program: this.eprogram,
      };
      const url = `${this.$appUrl}/updateprogram`;
      axios
        .post(url, data)
        .then((res) => {
          if (res.data.code === "s200") {
            this.programForms[this.programForms.indexOf(this.item)].program =
              this.eprogram;
            this.programForms[
              this.programForms.indexOf(this.item)
            ].programcode = this.ecode;
            this.programForms[this.programForms.indexOf(this.item)].slug =
              this.eslug;
            this.eslug = "";
            this.eprogram = "";
            this.ecode = "";
            this.dialogEdit = false;
            this.program = "";
            Swal.fire("Success", "Updated successfully", "success");
          } else {
            Swal.fire("Error", "Something went wrong! Try again", "error");
          }
        })
        .catch((error) => {
          console.log(error);
          Swal.fire("Error", "Something went wrong", "error");
        });
    },

    deleteItemConfirm() {
      const item = this.item;
      console.log(item);
      const data = {
        id: item.programcode,
      };
      const url = `${this.$appUrl}/deleteprogram`;
      axios
        .post(url, data)
        .then((res) => {
          if (res.data.code === "00") {
            this.programForms.splice(this.editedIndex, 1);
            this.closeDelete();
            Swal.fire("Success", "Successfully deleted", "success");
          } else {
            Swal.fire("Oops..", "Something went wrong", "error");
          }
        })
        .catch(() => {
          Swal.fire("Oops..", "Something went wrong", "error");
        });
    },

    close() {
      this.dialog = false;
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.programForms[this.editedIndex], this.editedItem);
      } else {
        this.programForms.push(this.editedItem);
      }
      this.close();
    },
  },
};
</script>

<style>
</style>