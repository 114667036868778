<template>
    <div class="home">
        <div class="row">
            <div class="col-md-12" style="margin-top:70px !important; height:70px !important; padding:30px 0 0px 10px; position:fixed; z-index: 1; background:#fff; border-bottom: solid 1px #ccc; padding-left:40px">
                <span style="color:#777; font-size:16px;padding:10px 0 10px 10px 10px;">Programe Settings </span>/<span style="padding:5px; color:#555">{{header}} Program Setup {{ showBack === false ? session.name : ''}}</span>
            </div>
        </div>
    
        <div style="background: #f5f5f5; margin-top:180px" class=" container-fluid">
            <v-row style="margin:0 !important; padding:0 !important">
        <v-col cols="12" style="">
            <div v-if="showView" class="col-md-5 ma-auto" style="margin-top:100px">
                    <v-card elevation="3" height="auto" style="padding-bottom:20px">
                        <v-card-title style="color:#666; font-size:18px">
                            Select session and degree to setup
                        </v-card-title>
                        <v-divider style="margin-top:0px"></v-divider>
    
                        <v-card-text cols="11" style="margin:auto;padding-top:15px" pa-4>
    
                            <v-select outlined label="Select Session" :items="sessions" v-model="session"></v-select>
                    <v-select outlined label="Select Degree" :items="degrees" v-model="degree"></v-select>
                        </v-card-text>
                        <v-card-actions>
                            <v-btn color="#c0e7fe" @click="openSettingsType" :disabled="staffId==''"  class="col-md-7 ma-auto" >Next</v-btn>
                            <!-- <v-btn color="primary" elevation='2' class="col-md-7 ma-auto"  style="background-color:#e0e0e0 !important;color:#444  !important;padding:10px;cursor: pointer !important; " disabled > <v-progress-circular
          indeterminate
          color="#777" :size="25"></v-progress-circular> &nbsp;Finding staff</v-btn> -->
                            </v-card-actions>
                    </v-card>
                </div>
                <div v-else>
            <!-- <div class="tab">
                <button @click="showCont()" class="firstTab">
                    <h4>
                        First Semester
                    </h4>
                </button>
                <button @click="showsecondCont()" class="firstTab">
                    <h4>
                        Second Semester
                    </h4>
                </button>
            </div> -->
            <v-tabs v-model="tab" grow>
                <v-tab  @click="showCont()" class="firstTab">
                    First Semester
                </v-tab>
                <v-tab @click="showsecondCont()" >
                    Second Semester
                </v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab" class="pa-8" style="overflow-x: hidden">
                <v-tab-item >
                    <div id="firstSemesterCnt">
                        <div class="col-lg-12">
                    
                            <h4 class="">First Semester Setup</h4>
                        </div>
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="firstTabresult">
                                    <h6>Result</h6>
                                </div>
                                <div class="frstTabtxt mt-5">
                                    <h6>Fees</h6>
                                    <p>Fees to pay before result can be seen</p>
                                    <!-- <v-select outlined v-model="resultFee" :items="fees"></v-select> -->
                                    <div class="row">
                    
                                        <v-combobox hide-selected v-model="resultFees" :items="fees" clearable label="Select Fees" outlined
                                            multiple chips>
                                            <template v-slot:selection="data">
                                                <span class="chip"> {{data.item.text}} <span class="chip-cancle"
                                                        @click="removeResultFees(data.item)">x</span></span>
                                            </template>
                                        </v-combobox>
                                    </div>
                    
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="firstTabresult2">
                                    <h6>Approvals</h6>
                                    <div>
                                        <v-checkbox v-model="stepdown" @select="stepdown" label="Approve CA" color="primary" value="primary"
                                            hide-details></v-checkbox>
                                        <v-row>
                                            <v-col cols="12" sm="12">
                                                <div class="my-5">
                                                    <v-row v-if="stepdown">
                                                        <v-col cols="6" sm="6">
                                                            <v-subheader class="Catxt mt-1">Number of CA Approvals</v-subheader>
                                                        </v-col>
                                                        <v-col cols="6" sm="6">
                                                            <v-text-field outlined :rules="[rules.max, rules.required]" v-model="numOfCA"
                                                                type="number" dense></v-text-field>
                                                        </v-col>
                                                    </v-row>
                                                </div>
                                            </v-col>
                                        </v-row>
                                    </div>
                                    <div>
                                        <v-checkbox v-model="stepdown2" @select="stepdown2" label="Approve EXAM" color="primary"
                                            value="primary" hide-details></v-checkbox>
                                        <v-row>
                                            <v-col cols="12" sm="12">
                                                <div class="my-5">
                                                    <v-row v-if="stepdown2">
                                                        <v-col cols="6" sm="6">
                                                            <v-subheader class="Catxt mt-1">Number of EXAM Approvals</v-subheader>
                                                        </v-col>
                                                        <v-col cols="6" sm="6">
                                                            <v-text-field v-model="numOfExam" :rules="[rules.max, rules.required]" outlined
                                                                type="number" dense></v-text-field>
                                                        </v-col>
                                                    </v-row>
                                                </div>
                                            </v-col>
                                        </v-row>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="firstTabresult2">
                                    <h6>Courses</h6>
                                </div>
                                <div class="frstTabtxt">
                                    <h6>Fees</h6>
                                    <p>Fees to pay before result can be seen</p>
                                    <!-- <v-select outlined v-model="courseFee" :items="fees"></v-select> -->
                                    <div class="row">
                                        <v-combobox hide-selected v-model="courseFees" :items="fees" clearable label="Select Fees" outlined
                                            multiple chips>
                                            <template v-slot:selection="data">
                                                <span class="chip"> {{data.item.text}} <span class="chip-cancle"
                                                        @click="removeCourseFees(data.item)">x</span></span>
                                            </template>
                                        </v-combobox>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="firstTabresult2">
                                    <h6>GS Courses</h6>
                                </div>
                                <div class="frstTabtxt">
                                    <h6>Fees</h6>
                                    <p>Fees to paid before GS can be registered</p>
                                    <!-- <v-select outlined v-model="courseFee" :items="fees"></v-select> -->
                                    <div class="row">
                                        <v-combobox hide-selected v-model="gsCourseFees" :items="fees" clearable label="Select Fees"
                                            outlined multiple chips>
                                            <template v-slot:selection="data">
                                                <span class="chip"> {{data.item.text}} <span class="chip-cancle"
                                                        @click="removeGsCourseFees(data.item)">x</span></span>
                                            </template>
                                        </v-combobox>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="firstTabresult3">
                                    <div class="row">
                                        <div class="mt-5 col-lg-6">
                                            <h6>Registration Mode</h6>
                                        </div>
                                        <div class="col-lg-6">
                                            <div class="switchReg">
                                                <p class="mr-2 mt-3">Per Year</p>
                                                <v-switch v-model="registrationmode"></v-switch>
                                                <p class="ml-2 mt-3">Per Semester</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mt-8">
                                        <div class="row">
                                            <div class="col-lg-12">
                                                <h6>Carry Over</h6>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-lg-8">
                                                <h4 class="mr-10">Enforce Carry Over Registration</h4>
                                            </div>
                                            <div class="col-lg-4">
                                                <div class="switchReg">
                                                    <p class="mr-2 mt-3">Yes</p>
                                                    <v-switch v-model="enforceCarryOver"></v-switch>
                                                    <p class="ml-2 mt-3">No</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-lg-8">
                                                <h4 class="mr-10">Enforce Max Credit Load</h4>
                                            </div>
                                            <div class="col-lg-4">
                                                <div class="switchReg">
                                                    <p class="mr-2 mt-3">Yes</p>
                                                    <v-switch v-model="enforceCreditLoad"></v-switch>
                                                    <p class="ml-2 mt-3">No</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </v-tab-item>
                <v-tab-item>
                    <div id="secondSemesterCnt" v-if="secondSemesterCnt" >
                        <div class="row">
                            <div class="col-lg-12">
                    
                                <h4 class="">Second Semester Setup</h4>
                            </div>
                            <div class="col-lg-6">
                    
                                <div class="firstTabresult">
                                    <h6>Result</h6>
                                </div>
                                <div class="frstTabtxt">
                                    <h6>Fees</h6>
                                    <p>Fees to pay before result can be seen</p>
                                    <!-- <v-select outlined v-model="resultFee" :items="fees"></v-select> -->
                                    <div class="row">
                    
                                        <v-combobox hide-selected v-model="resultFeesTwo" :items="fees" label="Select Fees" outlined
                                            multiple chips>
                                            <template v-slot:selection="data">
                                                <span class="chip"> {{data.item.text}} <span class="chip-cancle"
                                                        @click="removeResultFeesTwo(data.item)">x</span></span>
                                            </template>
                                        </v-combobox>
                                    </div>
                    
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="firstTabresult2">
                                    <h6>Approvals</h6>
                                    <div>
                                        <v-checkbox v-model="stepdownTwo" label="Approve CA" color="primary" value="primary" hide-details>
                                        </v-checkbox>
                                        <v-row>
                                            <v-col cols="12" sm="12">
                                                <div class="my-5">
                                                    <v-row v-if="stepdownTwo">
                                                        <v-col cols="6" sm="6">
                                                            <v-subheader class="Catxt mt-1">Number of CA Approvals</v-subheader>
                                                        </v-col>
                                                        <v-col cols="6" sm="6">
                                                            <v-text-field outlined :rules="[rules.max, rules.required]" v-model="numOfCATwo"
                                                                type="number" dense></v-text-field>
                                                        </v-col>
                                                    </v-row>
                                                </div>
                                            </v-col>
                                        </v-row>
                                    </div>
                                    <div>
                                        <v-checkbox v-model="stepdownTwo2" label="Approve EXAM" color="primary" value="primary"
                                            hide-details></v-checkbox>
                                        <v-row>
                                            <v-col cols="12" sm="12">
                                                <div class="my-5">
                                                    <v-row v-if="stepdownTwo2">
                                                        <v-col cols="6" sm="6">
                                                            <v-subheader class="Catxt mt-1">Number of EXAM Approvals</v-subheader>
                                                        </v-col>
                                                        <v-col cols="6" sm="6">
                                                            <v-text-field v-model="numOfExamTwo" :rules="[rules.max, rules.required]"
                                                                outlined type="number" dense></v-text-field>
                                                        </v-col>
                                                    </v-row>
                                                </div>
                                            </v-col>
                                        </v-row>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="firstTabresult2">
                                    <h6>Courses</h6>
                                </div>
                                <div class="frstTabtxt">
                                    <h6>Fees</h6>
                                    <p>Fees to pay before result can be seen</p>
                                    <!-- <v-select outlined v-model="courseFee" :items="fees"></v-select> -->
                                    <div class="row">
                    
                                        <v-combobox hide-selected v-model="courseFeesTwo" :items="fees" clearable label="Select Fees"
                                            outlined multiple chips>
                                            <template v-slot:selection="data">
                                                <span class="chip"> {{data.item.text}} <span class="chip-cancle"
                                                        @click="removecourseFeesTwo(data.item)">x</span></span>
                                            </template>
                                        </v-combobox>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="firstTabresult2">
                                    <h6>GS Courses</h6>
                                </div>
                                <div class="frstTabtxt">
                                    <h6>Fees</h6>
                                    <p>Fees to paid before GS can be registered</p>
                                    <!-- <v-select outlined v-model="courseFee" :items="fees"></v-select> -->
                                    <div class="row">
                                        <v-combobox hide-selected v-model="gsCourseFeesTwo" :items="fees" clearable label="Select Fees"
                                            outlined multiple chips>
                                            <template v-slot:selection="data">
                                                <span class="chip"> {{data.item.text}} <span class="chip-cancle"
                                                        @click="removeGsCourseFeesTwo(data.item)">x</span></span>
                                            </template>
                                        </v-combobox>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="firstTabresult3">
                                    <div class="row">
                                        <div class="mt-5 col-lg-6">
                                            <h6>Registration Mode</h6>
                                        </div>
                                        <div class="col-lg-6">
                                            <div class="switchReg">
                                                <p class="mr-2 mt-3">Per Year</p>
                                                <v-switch v-model="registrationmodeTwo"></v-switch>
                                                <p class="ml-2 mt-3">Per Semester</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mt-8">
                                        <div class="row">
                                            <div class="col-lg-12">
                                                <h6>Carry Over</h6>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-lg-8">
                                                <h4 class="mr-10">Enforce Carry Over Registration</h4>
                                            </div>
                                            <div class="col-lg-4">
                                                <div class="switchReg">
                                                    <p class="mr-2 mt-3">Yes</p>
                                                    <v-switch v-model="enforceCarryOverTwo"></v-switch>
                                                    <p class="ml-2 mt-3">No</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-lg-8">
                                                <h4 class="mr-10">Enforce Max Credit Load</h4>
                                            </div>
                                            <div class="col-lg-4">
                                                <div class="switchReg">
                                                    <p class="mr-2 mt-3">Yes</p>
                                                    <v-switch v-model="enforceCreditLoadTwo"></v-switch>
                                                    <p class="ml-2 mt-3">No</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </v-tab-item>
            </v-tabs-items>
            <div class="save">
                <button @click="save">Save</button>
            </div>
        </div>
        </v-col>
        </v-row>

    </div>
        <v-dialog width="500" v-model="shownext">
            <v-card style="overflow: hidden">
                <!-- <div style="background: #006794; color: #fff" class="pa-5">Select Settings type</div> -->
                <v-card-title style="color:#666; font-size:18px">
                            Select Setting Mode
                        </v-card-title>
                        <v-divider style="margin-top:0px"></v-divider>
                        <v-card-text>
                <div class="pa-4">
                    <v-radio-group v-model="settingsType">
                        <v-radio label="Proceed to settings" :value="true"></v-radio>
                        <v-radio label="Import Settings from previous session" :value="false">
                        </v-radio>
    
                    </v-radio-group>
                    <div class="row">
                        <div class="col-6">
                            <v-btn block style="background-color: #ccc" @click="shownext = false">Cancle</v-btn>
                        </div>
                        <div class="col-6">
                            <v-btn block color="primary" @click="procced">Next</v-btn>
                        </div>
                    </div>
                </div>
            </v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog width="500" v-model="showImport">
            <v-card style="overflow: hidden">
                <div style="background: #006794; color: #fff" class="pa-5">Import settings from previous session</div>
                <div class="pa-10">
                    <div class="mb-5">
                        <label for="">Select session to procceed</label>
                        <v-select :items="sessions" v-model="importSession" label="Select Session"></v-select>
                    </div>
                    <div>
                        <v-btn color="#006798" style="color: #fff !important" @click="handleFetchSettings" block>
                            Next</v-btn>
                    </div>
                </div>
            </v-card>
        </v-dialog>
    
    
       
    </div>
    </template>
    
    <script>
    import Swal from 'sweetalert2';
    import axios from 'axios';
    import {degree} from '@Appmart/utility'
    
    // import axios from 'axios';
    export default {
        data: () => ({
            degrees: [],
            degree: '',
            departmentalLevy: true,
            showView: true,
            shownext: false,
            settingsType: true,
            stepdown: false,
            showBack: true,
            stepdown2: false,
            fees: [],
            session: '',
            showImport: false,
            resultFees: [],
            courseFees: [],
            gsCourseFeesTwo: [],
            gsCourseFees: [],
            sessions: ['2020/2021','2021/2022'],
            stepdown3: false,
            stepdown4: false,
            registrationmode: false,
            resultFee: '',
            courseFee: '',
            numOfCA: '',
            importSession: '',
            numOfExam: '',
            firstSemesterCnt: true,
            enforceCarryOver: false,
            enforceCreditLoad: false,
            secondSemesterCnt: false,
            resultFeesTwo: [],
            stepdownTwo: false,
            numOfCATwo: '',
            stepdownTwo2: false,
            numOfExamTwo: '',
            courseFeesTwo: [],
            registrationmodeTwo: false,
            enforceCarryOverTwo: false,
            enforceCreditLoadTwo: false,
            selection: [],
            selected: [],
            programInfo: null,
            otherFee: false,
            rules: {
                required: value => !!value || 'Required.',
                max: value => value < 6 || 'Number of approvals can not be more that 5',
                min: value => value > 1 || 'Number of approvals can be less thatn 1',
            },
    
        }),
        computed: {
                header() {
                    return `${this.programInfo.name[0]}${this.programInfo.name.split('').splice(1).join('').toLowerCase()}`;
                }
            },
        watch: {
            departmentalLevy(val) {
                if (val) {
                    this.otherFee = false
                }
            },
            otherFee(val) {
                if (val) {
                    this.departmentalLevy = false
                }
            }
        },
    
        beforeMount() {
                const data = window.atob(this.$route.params.id)
                this.programInfo = JSON.parse(data)
                // this.fetchSessions();
             this.fetchallDegrees();
            },
    
        methods: {
            async fetchallDegrees() {
                    try {
                        //const res = await axios.get('http://localhost:5000/getdegrees');
        
                        // console.log(res);
        
                        const activeDegrees = await degree().then(activeSet => {
      return activeSet;
    }).catch(e => {
      console.log(e);
    })
                        if (activeDegrees.data.code == 's200') {
                            this.degrees = activeDegrees.data.message.map(el => {
                                return {
                                    text: el.name,
                                    value: el._id
                                }
                            })
                        }
        
                    } catch (error) {
                        console.log(error);
                    }
                },
            openSettingsType() {
                if (!this.session || !this.degree) return Swal.fire({
                    icon: 'error',
                    text: 'Please select a session to proceed',
                    title: 'Oops..'
                });
                this.shownext = true;
            },
            procced() {
                if (this.settingsType) {
                    this.next();
                } else {
                    this.showImport = true
                }
    
                this.shownext = false;
    
            },
            handleFetchSettings() {
                this.next();
                this.initialize()
            },
            removeResultFees(item) {
                this.resultFees.splice(this.resultFees.indexOf(item), 1);
            },
            removeCourseFees(item) {
                this.courseFees.splice(this.courseFees.indexOf(item), 1);
            },
            removeGsCourseFees(item) {
                this.gsCourseFees.splice(this.gsCourseFees.indexOf(item), 1);
            },
            removeResultFeesTwo(item) {
                this.resultFeesTwo.splice(this.resultFeesTwo.indexOf(item), 1);
            },
    
            removecourseFeesTwo(item) {
                this.courseFeesTwo.splice(this.courseFeesTwo.indexOf(item), 1);
            },
            removeGsCourseFeesTwo(item) {
                this.gsCourseFeesTwo.splice(this.gsCourseFeesTwo.indexOf(item), 1);
            },
            moveBack() {
                this.showBack = true;
                this.showView = true;
                this.session = '';
            },
            async next() {
                if (!this.session) return Swal.fire({
                    icon: 'error',
                    text: 'Please select a session to proceed',
                    title: 'Oops..'
                });
                this.resultFees = [];
                this.resultFeesTwo = [];
                this.registrationmode = false;
                this.courseFees = [];
                this.gsCourseFees = [];
                this.numOfCA = '';
                this.numOfExam = '';
                this.enforceCarryOver = false;
                this.enforceCreditLoad = false;
    
                this.registrationmodeTwo = false;
                this.courseFeesTwo = [];
                this.gsCourseFeesTwo = [];
                this.numOfCATwo = '';
                this.numOfExamTwo = '';
                this.enforceCarryOverTwo = false;
                this.enforceCreditLoadTwo = false;
                // console.log({fees:this.resultFees},)
                this.fetchFees();
                this.showView = false;
                this.showBack = false;
            },
            async fetchSessions() {
                try {
                    const res = await axios.get('http://localhost:5000/fetch-session');
                    if (res.data.code === 's200') {
                        res.data.message.forEach(session => {
                            this.sessions.push({
                                text: session.academicYear,
                                value: {
                                    id: session._id,
                                    name: session.academicYear
                                }
                            })
                        });
    
                    }
                } catch (error) {
                    console.log(error);
                }
            },
            async initialize() {
                try {
                    let payload;
                    if(this.showImport){
                        payload = {
                            programId: this.programInfo.fid,
                            session: this.importSession.id,
                            degreeId: this.degree
                        }
                    }else{
    
                         payload = {
                            programId: this.programInfo.fid,
                            session: this.session.id,
                            degreeId: this.degree
                        }
                    }
                    const res = await axios.post(`http://localhost:5000/get-program-setting`, payload);
                    if (res.data.code == 's200') {
                        this.importSession = '';
                        this.degree = '';
                        this.showImport = false;
                        // console.log('Program Setup Res',res)
    
                        const firstSemester = res.data.message.firstSemester;
                        const secondSemester = res.data.message.secondSemester;
    
                        if (firstSemester) {
                            this.registrationmode = firstSemester.settings.course.registrationMode == 1 ? true : false;
                            this.resultFees = firstSemester.settings.result.resultCompulsoryFee;
                            this.courseFees = firstSemester.settings.course.courseCompulsoryFee;
                            this.gsCourseFees = firstSemester.settings.course.gsCourseFees;
                            this.numOfCA = firstSemester.settings.course.numberOfExam;
                            this.numOfExam = firstSemester.settings.course.numberOfCA;
                            this.enforceCarryOver = firstSemester.settings.result.enforceCarryOver == 1 ? true : false;
                            this.enforceCreditLoad = firstSemester.settings.result.enforceMaxCreditLoad == 1 ? true : false;
                            // console.log({fees:this.resultFees},)
                        }
    
                        if (secondSemester) {
                            this.registrationmodeTwo = secondSemester.settings.course.registrationMode == 1 ? true : false;
                            this.resultFeesTwo = secondSemester.settings.result.resultCompulsoryFee;
                            this.courseFeesTwo = secondSemester.settings.course.courseCompulsoryFee;
                            this.gsCourseFeesTwo = secondSemester.settings.course.gsCourseFees;
                            this.numOfCATwo = secondSemester.settings.course.numberOfExam;
                            this.numOfExamTwo = secondSemester.settings.course.numberOfCA;
                            this.enforceCarryOverTwo = secondSemester.settings.result.enforceCarryOver == 1 ? true : false;
                            this.enforceCreditLoadTwo = secondSemester.settings.result.enforceMaxCreditLoad == 1 ? true : false;
                            // console.log({fees2:this.resultFeesTwo},)
    
                        }
                    }
                } catch (error) {
                    console.log(error);
                }
            },
            async postData(payload) {
                try {
                    const res = await axios.post('http://localhost:5000/program-setup', payload);
    
                    return res;
                } catch (error) {
                    console.log(error);
                }
            },



            ////// fetch the fees that have been setup for the session selected
            async fetchFees() {
                try {
                    this.fees = [];
                    var url = `${this.$appUrl}/session-fees`;
                    const res = await axios.post(url, {
                        session: this.session.name
                    });
                    // console.log('REESSSS', res);
                    if (res.data.code === 's200') {
                        res.data.message.forEach(fee => {
                            this.fees.push({
                                text: fee.name,
                                value: fee._id
                            })
                        });
                        this.initialize();
    
                    }
                } catch (error) {
                    console.log(error);
                }
            },



            async save() {
                try {
                    let payload;
                    // const postData = await axios.post('http://localhost:5000/program-setup', )
                    if (this.firstSemesterCnt) {
                        console.log('First semester');
                        if (this.courseFees.length === 0 || this.resultFees.length === 0 || this.gsCourseFees.length === 0 || !this.numOfExam || !this.numOfCA) {
                            return Swal.fire({
                                icon: 'error',
                                text: 'Please make sure you fill in all the provided feilds'
                            })
                        }
                        const settings = {
                            degreeId: this.degree,
                            course: {
    
                                numberOfCA: this.numOfCA,
                                numberOfExam: this.numOfExam,
                                registrationMode: this.registrationmode ? 1 : 2,
                                courseCompulsoryFee: this.courseFees,
                                gsCourseFees: this.gsCourseFees
                            },
                            result: {
                                enforceCarryOver: this.enforceCarryOver ? 1 : 2,
                                enforceMaxCreditLoad: this.enforceCreditLoad ? 1 : 2,
                                resultCompulsoryFee: this.resultFees,
    
                            }
                        };
    
                        payload = {
                            settings,
                            programId: this.programInfo.fid,
                            semester: 1,
                            createdBy: 'skolarsuite',
                            academicSession: this.session
                        }
    
                        const resData = await this.postData(payload);
    
                        if (resData.data.code === 's200') {
                            this.degree = '';
                            Swal.fire({
                                icon: 'success',
                                text: 'Settings saved successfully',
                                title: 'Successful'
                            });
                        } else {
                            Swal.fire({
                                icon: 'error',
                                text: resData.data.message,
                                title: 'Oops..'
                            })
                        }
    
                    } else if (this.secondSemesterCnt) {
                        if (this.courseFeesTwo.length === 0 || this.resultFeesTwo.length === 0 || this.gsCourseFeesTwo.length === 0 || !this.numOfExamTwo || !this.numOfCATwo) {
                            return Swal.fire({
                                icon: 'error',
                                text: 'Please make sure you fill in all the provided feilds'
                            })
                        }
                        const settings = {
                            degreeId: this.degree,
                            course: {
    
                                numberOfCA: this.numOfCATwo,
                                numberOfExam: this.numOfExamTwo,
                                registrationMode: this.registrationmodeTwo ? 1 : 2,
                                courseCompulsoryFee: this.courseFeesTwo,
                                gsCourseFees: this.gsCourseFeesTwo
                            },
                            result: {
                                enforceCarryOver: this.enforceCarryOverTwo ? 1 : 2,
                                enforceMaxCreditLoad: this.enforceCreditLoadTwo ? 1 : 2,
                                resultCompulsoryFee: this.resultFeesTwo,
    
                            }
                        };
    
                        payload = {
                            settings,
                            programId: this.programInfo.fid,
                            semester: 2,
                            createdBy: 'skolarsuite',
                            academicSession: this.session
                        }
    
                        const resData = await this.postData(payload);
    
                        console.log(resData);
                        if (resData.data.code === 's200') {
                            this.degree = '';
                            Swal.fire({
                                icon: 'success',
                                text: 'Settings saved successfully',
                                title: 'Successful'
                            });
                        } else {
                            Swal.fire({
                                icon: 'error',
                                text: resData.data.message,
                                title: 'Oops..'
                            })
                        }
    
                    }
    
                } catch (error) {
                    console.log(error);
                }
            },
            showCont() {
                this.firstSemesterCnt = true
                this.secondSemesterCnt = false
            },
    
            showsecondCont() {
                this.firstSemesterCnt = false
                this.secondSemesterCnt = true
            },
    
            pushBacktoTable() {
                setTimeout(() => {
                    this.$router.push('/createprogram')
                }, 1000);
            }
    
        },
    }
    </script>
    
    