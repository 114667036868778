<template>
<div class="home">
    <div class="row">
        <div class="col-md-12" style="margin-top:70px !important; height:70px !important; padding:30px 0 0px 10px; position:fixed; z-index: 1; background:#fff; border-bottom: solid 1px #ccc; padding-left:40px">
            <span style="color:#777; font-size:18px;padding:10px;">Admin User - {{staffData.prefix}} {{staffData.surname}} {{staffData.name}}</span>
        </div>
    </div>
         <div style="background: #f5f5f5; margin-top:150px; " class=" container-fluid row">
         <div style="background-color:#F7DBCC; height: 350px;" class="col-md-3">
<div id="avatar" style="margin:auto"></div>
<!-- {{staffData}} -->
<div class="col-md-12" style="margin-top:-20px; text-align:center; font-weight:600; color:#555"><i class="fa fa-book"></i>
{{staffData.prefix}} {{staffData.surname}} {{staffData.name}}
</div>
<div class="col-md-12" style="margin-top:-30px; text-align:center; font-weight:; color:#555"><i class="fa fa-book"></i>
{{staffData.cadre}} 
</div>

<div class="col-md-3" style="background-color:#4BB543; margin:auto; border-radius: 50px;padding:3px !important; text-align: center; font-size:14px; color:#fff; font-weight: 600; margin-top:-10px">Active
<!-- <div class="dotactive" style=""></div> -->

</div>

<button class="btn-bot btn btn-danger ">Deactivate Account</button>
         </div>
         <div class="col-md-9">
<div style="background-color:#fff; heigh:auto; margin-top:10px; min-height: 320px;" class="col-md-12">

          <v-tabs
            v-model="tab"
        >
            <v-tabs-slider></v-tabs-slider>

            <v-tab href="#subscribe">
                User Profile
            </v-tab>

            <v-tab href="#contact">
                Reset Password
            </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
            <v-tab-item :key="1" value="subscribe">
                <v-card flat>
                    <v-card-text>
                        <!-- {{staffData}} -->
                        <div class="col-md-6" style="margin:0; padding-top:20px">
                        <v-text-field style="padding:3px 0 3px 0 !important"  type="text" v-model="staffData.othernames" name="othernames" id="othernames" placeholder="Type Your e-mail Address" label="Other Names" outlined readonly v-if="staffData.othernames !=''"></v-text-field>

                        <v-text-field type="text" v-model="staffData.email" name="email" id="email" placeholder="Type Your e-mail Address" autocomplete="off" @keyup="validateEmail()" label="Email Address"  outlined readonly></v-text-field>

                        <v-text-field type="text" v-model="staffData.phone" name="phone" id="phone" placeholder="Type Your e-mail Address" autocomplete="off" @keyup="validateEmail()" label="Phone No" outlined readonly></v-text-field>

                        <v-text-field type="text" v-model="staffData.phone" name="phone" id="phone" placeholder="Type Your e-mail Address" autocomplete="off" @keyup="validateEmail()" label="Phone No" outlined readonly></v-text-field>

                         <v-text-field type="text" v-model="staffData.teaching_status" name="phone" id="phone" placeholder="Type Your e-mail Address" autocomplete="off" @keyup="validateEmail()" label="Staff status" outlined readonly></v-text-field>

                        </div>
                    </v-card-text>
                </v-card>
            </v-tab-item>
            <v-tab-item :key="2" value="contact">
                <v-card flat>
                    <v-card-text style="margin-top:50px; font-size:16px; text-align:center">
                        This service is not available for your institution.<br> You can only change user password from the CIRMS Portal.<br>
                        Click <a href="">here</a> to visit the CIRMS portal
                    </v-card-text>
                </v-card>
            </v-tab-item>
        </v-tabs-items>   
                </div>
                </div>
</div>

    </div>
</template>

<script>
import axios from 'axios';
import Swal from 'sweetalert2';
// import {} from 'uuid';

export default {
    data() {
        return {
                tab: "password",
            showAddDepartment: false,
            selected1: [],
            selected2: [],
            selected3: [],
            selected4: [],
            selected5: [],
            selected6: [],
            selected7: [],
            selected8: [],
            selected9: [],
            selected10: [],
            // desserts: null,
            Program1: null,
            Program2: null,
            Program3: null,
            Program4: null,
            Program5: null,
            Program6: null,
            Program7: null,
            Program8: null,
            Program9: null,
            Program10: null,
            programNames: [],
            headers: [
                // {
                //     text: 'Menu',
                //     value: 'menu'
                // },
                {
                    text: 'Menu Name',
                    value: 'menu_name'
                },
                {
                    text: '',
                    value: ''
                },
                {
                    text: 'Department',
                    value: 'department_assigned'
                },
                {
                    text: 'Action',
                    value: 'action'
                }
            ],
            selectionType: "leaf",
            selected: [],
            programMenus: [],
            desserts: [],
            staffData: {},
            MenuArr: [],
            department: '',
            faculty: '',
            facultyHolder: [],
            faculties: [],
            departments: [],
            departmentsHolder: [],
            menu: {},
            selectectedMenu: null,
        }
    },
    watch: {
        faculty(val) {
            this.populateRightDepartments(val);
            // if (val && this.department) {
            //     this.disableFile = false;
            // }
        },
    },
    beforeMount() {
        this.staffData = JSON.parse(localStorage.getItem('_@$wsar15+0-'))
        // this.fetchMenus() 
        // this.fetchProgram()
        this.fetchMenusByProgram();
        this.fetchFaculties();
        this.fetchDepartments();

    },
    mounted() {

    },
    methods: {
        handleAssignDepartment() {
            if(!this.department || !this.faculty) return Swal.fire({icon: 'error', text: 'Please select department and faculty to proceed', title: 'Oops..'});
                console.log(this.selectectedMenu);
            
            this.selectectedMenu.menus.forEach(menu => {
                if (menu.sub_menu[menu.sub_menu.indexOf(this.menu)]){
                    menu.sub_menu[menu.sub_menu.indexOf(this.menu)].department_assigned = this.department;
                    return;
                }
            })

            this.showAddDepartment = false;
            this.department = '';
            this.faculty = '';
        },
        closeModal() {
            this.showAddDepartment = false;
        },
        assignDepartment(item, selectectedMenu) {
            this.menu = item;
            this.selectectedMenu = selectectedMenu;
            this.showAddDepartment = true;
            console.log(item);
        },
        async submit(program, selectedMenus) {

            try {
                // console.log(program, selectedMenus);

                if (selectedMenus.length == 0) return Swal.fire({
                    icon: 'error',
                    text: 'Please select a menu',
                    title: 'Oops..'
                });

                const dataMenu = selectedMenus.map(menu => {
                    return {
                        staff_id: this.staffData.staffid,
                        program_id: program.id,
                        //Submenu assigned
                        sub_menu: menu,
                        department_assigned: menu.department_assigned.map(el => el.value.id)
                    }
                });

                // console.log('DATA', dataMenu);
    var url = process.env.VUE_APP_ADMISSIONS_V1_API_ENDPOINT+"/assignMenu";
                const res = await axios.post(url, {
                    menus: dataMenu
                });

                if (res.data.code === 's200') {
                    Swal.fire({
                        icon: 'success',
                        text: res.data.message,
                        title: 'Successful'
                    });
                } else {
                    Swal.fire({
                        icon: 'error',
                        text: res.data.message,
                        title: 'Oops..'
                    });

                }

            } catch (error) {
                console.log(error);
            }

            /*
                        {
                staff_id: String,
                program_id: { type: mongoose.Schema.Types.ObjectId, ref: 'Program'},
                //Submenu assigned
                sub_menu: {
                    menu_name: String,
                    menu_icon: String,
                    menu_link: String,
                    menu_id: { type: mongoose.Schema.Types.ObjectId, ref: "Menu"},
                    created_on: { type: Date, default: Date.now()},
                    created_by: String,
                    status: { type: Number, default: 1}
                },
                //Parent Id
                menu_id: { type: mongoose.Schema.Types.ObjectId, ref: 'Menu'},
                dateAssigned: { type: Date, default: Date.now()},
                //Assigned departments
                department_assigned: [
                    {
                        type: mongoose.Schema.Types.ObjectId,
                        ref: 'Department'
                    }
                ],
                //1 => active, 0 => inactive
                status: { type: Number, default: 1
                }
            } 
                */
            // console.log(id);
        },
       async fetchAssignedMenus(){
            try {
                    var url = process.env.VUE_APP_ADMISSIONS_V1_API_ENDPOINT+"/fetchallAsignedMenu";
                const res = await axios.post(url, {staff_id: this.staffData.staffid});

                    console.log('RESPONSE',res);
                res.data.message.forEach(menu => {
                    this.programMenus.forEach((pmenu, i) => {
                        if(pmenu.program.id === menu.program_id){
                            this[`selected${i + 1}`].push(menu.sub_menu);
                            return;
                        }
                    })
                    
                })

                // const pmenus = [];
                // this.programMenus.forEach(el => {
                //     pmenus.push(...el.menus)
                // });

                res.data.message.forEach(menu => {
                    this.programMenus.forEach(pmenu =>{
                        pmenu.menus.forEach(cmenu => {
                            cmenu.sub_menu.forEach(sub => {
                                if(sub.id === menu.sub_menu.id){
                                    sub.department_assigned = menu.sub_menu.department_assigned;
                                }
                            })
                        })
                    })

                })

            } catch (error) {
                console.log(error);
            }
        },  
        populateRightDepartments(val) {
            const faculty = this.facultyHolder.find((el) => el.name === val);
            const facultyId = faculty._id;
            const departmentDatas = this.departmentsHolder.filter(
                (el) => el.faculty._id === facultyId
            );
            const departments = departmentDatas.map((el) => {
                return {
                    text: el.name,
                    value: {id: el._id, name: el.name}
                }
            });
            this.departments = departments;
        },
        close() {
            this.showAddDepartment = false;
            this.faculty = '';
            this.department = '';
            // console.log('close');
        },
        async fetchFaculties() {
            try {
                var url = process.env.VUE_APP_ADMISSIONS_V1_API_ENDPOINT2+"/get-faculty_standalone";
                const res = await axios.post(url);
                const facultyNames = res.data.message.map((el) => el.name);
                const datas = res.data.message.map((el) => el);
                // console.log('Faculty',res);
                this.facultyHolder = datas;

                this.faculties = facultyNames;
            } catch (error) {
                console.log(error);
            }
        },
        async fetchDepartments() {
            try {
                var url = process.env.VUE_APP_ADMISSIONS_V1_API_ENDPOINT2+"/get-department";
                const res = await axios.post(url);
                console.log('Department', res);
                const datas = res.data.data.map((el) => el);
                this.departmentsHolder = datas;
            } catch (error) {
                console.log(error);
            }
        },
        assign(item) {
            console.log(item)
        },
        async fetchMenusByProgram() {
            try {
            var url = process.env.VUE_APP_ADMISSIONS_V1_API_ENDPOINT+"/fetchProgramMenu";
                const res = await axios.get(url);
                if (res.data.code == 's200') {
                    this.programNames = res.data.message.programNames;
                    res.data.message.data.forEach(menu => {
                        // console.log('Menus', menu)
                        menu.menus.forEach(men => {
                            men.sub_menu.forEach((sub, i) => {
                                sub['department_assigned'] = [];
                                sub['id'] = `${i+1}${sub.menu_name.split(' ').join('').toLowerCase()}${men._id}`
                            })
                        })
                    });

                    this.programMenus = res.data.message.data;

                    for (let i = 0; i < this.programMenus.length; i++) {
                        console.log('Program' + ' ' + i, this.programMenus[i])
                        this[`Program${i + 1}`] = this.programMenus[i];

                    }
                    console.log('Program Menu', this.programMenus)
                    this.fetchAssignedMenus();


                }
            } catch (error) {
                console.log(error);
            }
        },
        splitMenuBasedOnProgram() {

        }
    }
}
</script>

<style lang="scss" scoped>
.btn-bot{
   position:absolute; 
   margin-left:-35px;
   left:35%;
   bottom:10px;
}
.label {
    padding: 20px 0;
    display: flex;

    h4 {
        font-family: Proxima Nova;
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 20px;
        color: #4f4f4f;
        padding-left: 40px;
    }

    button {
        width: 45px;
        height: 45px;
        background: #d6ecf5;
        border-radius: 3px;
    }
}

.save_button {
    padding: 15px;
    width: 149px;
    height: 45px;
    background: #006794;
    border-radius: 3px;

    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    align-items: center;
    text-align: center;
    color: #ffffff;
}

.contain {
    width: 100%;
    height: auto;
    background: #ffffff;
}

.menu_wrap {
    max-height: 500px;
    overflow-y: scroll;
}

.menu_wrap::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #F5F5F5;
}

.menu_wrap::-webkit-scrollbar {
    width: 8px;
    background-color: #F5F5F5;
}

.menu_wrap::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    background-color: #a7a7a7;
}
.button-sub2 {
    padding: 15px;
    height: auto;
    display: block;
    width: 100%;
    background: #006794;
    border-radius: 3px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    color: #FFFFFF;
}
.bio_details {
    h6 {
        margin-top: 20px;

        strong {
            font-family: Proxima Nova;
            font-style: normal;
            font-weight: bold;
            font-size: 20px;
            line-height: 114%;
            text-align: center;
            color: #4f4f4f;
        }
    }

    h5 {
        margin-top: 19px;

        span {
            font-family: Proxima Nova;
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 114%;
            color: #828282;
        }
    }
}
.setup_tap {
    border-left: 1px solid #e5e5e5;
}
#avatar {
    /* This image is 687 wide by 1024 tall, similar to your aspect ratio */
    background-image: url('../assets/user.svg');
    
    /* make a square container */
    width: 150px;
    height: 150px;

    /* fill the container, preserving aspect ratio, and cropping to fit */
    background-size: cover;

    /* center the image vertically and horizontally */
    background-position: top center;

    /* round the edges to a circle with border radius 1/2 container size */
    border-radius: 50%;
    margin-bottom:20px !important
}
</style>
