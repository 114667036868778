<template>
    <div class="home">
        <div class="row">
        <div class="col-md-12" style="margin-top:70px !important; height:70px !important; padding:30px 0 0px 10px; position:fixed; z-index: 1; background:#fff; border-bottom: solid 1px #ccc; padding-left:40px">
            <span style="color:#777; font-size:18px;padding:5px;"></span>Settings /<span style="padding:5px; color:#555">Create Fees</span>
        </div>
    </div>
    
    <div style="background: #f5f5f5; margin-top:180px" class=" container-fluid">

        <v-card class="mt-8 row">
            <div class="col-lg-3">
                <div class="select">
                    <label for=""><strong>Select Program</strong></label>
                    <v-select v-model="programSearch" :items="programs" label="Select Your Program" solo></v-select>
                </div>
            </div>
            <div class="col-lg-3">
                <div class="select">
                    <label for=""><strong>Select fee</strong></label>
                    <v-select v-model="feeName" :items="feeNames" label="Select Your Fee Name" editable solo></v-select>
                </div>
            </div>
            <div class="col-lg-3">
                <div class="select">
                    <label for=""><strong>Select status</strong></label>
                    <v-select v-model="status" :items="progra" label="Select Your Staus" solo></v-select>
                </div>
            </div>
            <div class="col-lg-3 pt-12">
                <div class="row">
                    <div class="col-6"></div>
                    <div class="col-6"></div>
                </div>
                <v-btn class="button-search" @click="search">Search</v-btn>
                <v-btn class="button-search ml-3" @click="clear">Clear</v-btn>
            </div>
            <div class="col-12">
                <div class="row">
                    <div class="col-lg-12">

                        <v-data-table :headers="headers" :items="desserts" sort-by="calories" class="">
                            <template v-slot:top>
                                <v-toolbar flat>
                                    <v-spacer></v-spacer>
                                    <v-dialog v-model="dialog" max-width="500px" persistent>
                                        <template v-slot:activator="{ on, attrs }">
                                            <button class="button-search" v-bind="attrs" v-on="on">Create Fee</button>
                                        </template>
                                        <v-card class="mx-auto" max-width="500" outlined>
                                            <div class="feeTitle">
                                                <span class="text-h5">{{ formTitle }}</span>
                                            </div>
                                            <div class="log-field">
                                                <div class="select">
                                                    <v-overflow-btn class="my-2" v-model="program" :items="programs" label="Select Your Program"></v-overflow-btn>
                                                </div>
                                                <div v-if="program">
                                                    <div class="select">
                                                        <v-text-field outlined v-model="fee_name" label="Fee Name"></v-text-field>
                                                    </div>
                                                           <div class="select">
                                                        <v-select outlined label="Select Session" :items="sessions" v-model="session"></v-select>
                                                    </div>
                                                    <div class="select">
                                                        <v-textarea outlined v-model="fee_desc" solo name="input-7-4" label="Fee Description"></v-textarea>
                                                    </div>
                                               
                                                    <!-- <div class="select">
                                                        <v-checkbox v-model="importFee" label="Import fee template from existing fee"></v-checkbox>
                                                    </div>
                                                    <div v-if="importFee">
                                                        <v-select outlined label="Import fee" :items="feeNames" v-model="feeImport"></v-select>
                                                    </div> -->
                                                </div>
                                                <div>
                                                    <button style="background-color: gray; color: #fff; margin-right: 1rem" class="button-search" @click="closeDialog">Cancle</button>
                                                    <button class="button-search" @click="save">Add Fee</button>
                                                </div>
                                            </div>
                                        </v-card>
                                    </v-dialog>
                                    <v-dialog v-model="dialogEdit" max-width="500px">
                                        <v-card class="mx-auto" max-width="500" outlined>
                                            <div class="feeTitle">
                                                <span class="text-h5">{{ formTitle }}</span>
                                            </div>
                                            <div class="log-field">
                                                <div class="select">
                                                    <v-overflow-btn class="my-2" v-model="program" label="Select Your Program"></v-overflow-btn>
                                                </div>
                                                <div>
                                                    <div class="select">
                                                        <v-text-field outlined v-model="fee_name" label="Fee Name"></v-text-field>
                                                    </div>
                                                    <div class="select">
                                                        <v-textarea outlined v-model="fee_desc" solo name="input-7-4" label="Fee Description"></v-textarea>
                                                    </div>
                                                    <div>
                                                        <button class="button-search" @click="editFee">Edit Fee</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </v-card>
                                    </v-dialog>
                                    <v-dialog v-model="dialogDelete" max-width="500px">
                                        <v-card>
                                            <v-card-title class="text-h5">Are you sure you want to edit this item?</v-card-title>
                                            <v-card-actions>
                                                <v-spacer></v-spacer>
                                                <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                                                <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                                                <v-spacer></v-spacer>
                                            </v-card-actions>
                                        </v-card>
                                    </v-dialog>
                                </v-toolbar>
                            </template>
                            <template v-slot:item.actions="{ item }">
                                <v-icon small class="mr-2" @click="editItem(item)">
                                    mdi-pencil
                                </v-icon>
                                <v-icon small @click="deleteItem(item)">
                                    mdi-delete
                                </v-icon>
                                <!-- <v-icon small @click="redirect(item)">
                                    mdi-cogs
                                </v-icon> -->
                            </template>
                            <template v-slot:no-data>
                                <strong>No data</strong>
                            </template>
                        </v-data-table>
                    </div>
                </div>
            </div>
        </v-card>
</div>
</div>
</template>

<script>
import Swal from 'sweetalert2'
import axios from 'axios'
export default {
    data: () => ({
        feeImport: '',
        dialog: false,
        dialogDelete: false,
        programSearch: '',
        // feeName: '',
        status: null,
        session: '',
        sessions: [],
        feeName: '',
        feeDescription: '',
        importFee: false,
        programs: [],
        feeHolder: [],
        progra: [{
                text: 'Active',
                value: 1
            },
            {
                text: 'Not active',
                value: 0
            },
        ],
        feeNames: [],
        feeCollections: null,

        headers: [
            // {
            //     text: 'Fee code',
            //     value: 'ccode'
            // },
            {
                text: 'Fee Name',
                align: 'start',
                sortable: false,
                value: 'ctitle',
            },
            {
                text: 'Program',
                value: 'program'
            },
            {
                text: 'Fee Description ',
                value: 'desc'
            },

            {
                text: 'Status',
                value: 'stat'
            },
            {
                text: 'Date Created',
                value: 'date'
            },
            {
                text: 'Action',
                value: 'actions'
            },
        ],
        desserts: [],
        dialogEdit: false,
        editedIndex: -1,
        program: null,
        fee_name: null,
        fee_desc: null,
        defaultItem: {
            ctitle: '',
            ccode: '',
            cdes: '',
            comp: '',
            stat: '',
        },
        item: null,
    }),

    computed: {
        formTitle() {
            return this.editedIndex === -1 ? 'Add Fee' : 'Edit Fee'
        },
    },

    watch: {
        dialog(val) {
            val || this.close()
        },
        dialogDelete(val) {
            val || this.closeDelete()
        },
    },

    beforeMount() {
        this.fetchPrograms();
        this.fetchFees();
        this.fetchActiveSessions()
    },

    methods: {
        closeDialog(){
this.dialog = false;
this.program = null;
                    this.fee_desc = null;
                    this.fee_name = null;
                    this.session = '';
        },

        // async fetchPrograms() {
        //     try {
        //         const res = await axios.get('http://localhost:5000/fetchprograms');
        //         console.log('Programs', res);

        //         if (res.data.code == '00') {
        //             res.data.data.forEach(program => {
        //                 this.programs.push({
        //                     text: program.program,
        //                     value: {
        //                         val: program._id,
        //                         name: program.program
        //                     }
        //                 });
        //             });
        //             // console.log(this.programs);
        //         }
        //     } catch (error) {
        //         console.log(error);
        //     }
        // },
        async fetchActiveSessions(){
            try {
                const res = await axios.get('http://127.0.0.1:5000/fetch-active-session?curretFutureSession=1');
                
                if(res.data.code == 's200'){
                    res.data.message.forEach(el =>{
                        this.sessions.push({
                            text: el.slug,
                            value: el.slug
                        })
                    })
                }
            } catch (error) {
                console.log(error);
            }
        },
        clear() {
            if (this.feeHolder.length !== 0) {
                this.desserts = this.feeHolder;
            }
            this.programSearch = null;
            this.status = null;
            this.feeName = null;
        },
        async search() {
            // console.log('SEARC',this.programSearch, this.feeName, this.status);

            this.feeHolder = this.desserts;
            try {
                if (!this.programSearch && !this.feeName && this.status == null) {
                    this.desserts = [];
                    return this.fetchFees();
                }
                //Build query
                // let query;
                // if (this.programSearch && this.feeName && this.status) {
                //     console.log('check', this.programSearch, this.feeName, this.status);
                //     query = `program=${this.programSearch}&name=${this.feeName}&status=${Number(this.status)}`;
                // } else if (this.programSearch && this.feeName) {
                //     query = `program=${this.programSearch.val}&name=${this.feeName}`;
                // } else if (this.programSearch && this.status) {
                //     query = `program=${this.programSearch.val}&status=${Number(this.status)}`;
                // } else if (this.feeName && this.status) {
                //     query = `name=${this.feeName}&status=${Number(this.status)}`;
                // } else if (this.feeName) {
                //     query = `name=${this.feeName}`
                // } else if (this.programSearch) {
                //     query = `program=${this.programSearch.val}`
                // } else if (this.status == 0 || this.status == 1) {
                //     query = `status=${this.status}`;
                // }
                    // console.log('Query',query);
                    const url = process.env.VUE_APP_SETTINGS_V1_API_ENDPOINT2+`/get-fees/?name=${this.feeName ? this.feeName : undefined}&program=${this.programSearch ? this.programSearch.val : undefined}&status=${this.status ? this.status : undefined}`
                const res = await axios.get(url);
                if (res.data.code == 's200') {
                    this.desserts = [];
                    res.data.message.forEach(fee => {
                        this.desserts.push({
                            ctitle: fee.name,
                            id: fee._id,
                            program: fee.program.program,
                            desc: fee.description,
                            stat: fee.status === 1 ? 'Active' : 'Not Active',
                            date: fee.dateCreated.split('T')[0],
                        })
                    })
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops..',
                        text: res.data.message
                    })
                }

                // console.log('SEARCHED',res);

            } catch (error) {
                console.log(error);
            }

            // if (Swal.fire('Search Successful', 'Done')) {
            //     this.program = '', this.feeName = '', this.status = '';
            //     return;
            // }
        },

        editItem(item) {
            this.item = item;
            this.fee_name = item.ctitle;
            this.fee_desc = item.desc;
            const program = this.programs.find(pro => pro.text === item.program);
            //    console.log('Program',program)
            this.program = program;
            this.dialogEdit = true;
        },

        async editFee() {
            try {
                console.log(this.fee_name, this.fee_desc, this.program);
                if (!this.fee_name || !this.fee_desc || !this.program) {
                    return Swal.fire({
                        icon: 'info',
                        text: `Please fill in all fields`
                    })
                }

                const data = {
                    description: this.fee_desc,
                    name: this.fee_name,
                    program: this.program.value.val,
                    id: this.item.id
                }
                // console.log(res);
                const res = await axios.post('http://localhost:5000/edit-fee', data);
                if (res.data.code == 's200') {
                    const index = this.desserts.findIndex(fee => fee.id === this.item.id);
                    // console.log('index', index);
                    this.desserts[index].ctitle = this.fee_name;
                    this.desserts[index].desc = this.fee_desc;
                    this.desserts[index].program = this.program.text;
                    this.program = null;
                    this.fee_desc = null;
                    this.fee_name = null;

                    this.dialogEdit = false;
                    return Swal.fire({
                        icon: 'success',
                        text: 'Fee successfully updated',
                        title: 'Successful'
                    })
                } else {
                    return Swal.fire({
                        icon: 'error',
                        text: res.data.message,
                        title: 'Oops..'
                    })
                }
            } catch (error) {
                console.log(error);
            }
        },

        deleteItem(item) {
            this.item = item;
            this.editedIndex = this.desserts.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.dialogDelete = true;
        },

        async deleteItemConfirm() {
            try {
                const status = this.item.stat === 'Active' ? 0 : 1;
                const res = await axios.post('http://localhost:5000/activate-deactivate-fee', {
                    id: this.item.id,
                    status
                });
                if (res.data.code === 's200') {
                    this.item = null;
                    this.desserts[this.editedIndex].stat = status == 1 ? 'Active' : 'Not Active';
                    this.editedIndex = null;
                    this.closeDelete()
                    Swal.fire({
                        icon: 'success',
                        text: res.data.message,
                        title: 'Successful'
                    })
                } else {
                    Swal.fire({
                        icon: 'error',
                        text: res.data.message,
                        title: 'Oops..'
                    })
                }
            } catch (error) {
                Swal.fire({
                    icon: 'error',
                    text: 'Make your interet connection is active',
                    title: 'Oops..'
                })
                console.log(error);
            }

        },

        close() {
            this.dialog = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },

        closeDelete() {
            this.dialogDelete = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },

        async fetchFees() {
            try {
                const res = await axios.get('http://localhost:5000/get-fees');
                console.log('FEES', res);
                if (res.data.code == 's200') {
                    this.desserts = [];
                    res.data.message.forEach(fee => {
                        this.desserts.push({
                            ctitle: fee.name,
                            slug: `${fee.session}_${fee.name}`,
                            id: fee._id,
                            session: fee.session,
                            fee_id: fee.feeId,
                            programId: fee.program._id,
                            program: fee.program.program,
                            desc: fee.description,
                            stat: fee.status === 1 ? 'Active' : 'Not Active',
                            date: fee.dateCreated.split('T')[0],
                        })

                    })
                    const feeNamess = this.desserts.map(fee => fee.ctitle);
                    this.feeCollections = this.desserts.map(fee => {
                        return {
                            name: fee.ctitle,
                            id: fee.id
                        }
                    })
                    this.feeNames.push(...feeNamess);
                }
            } catch (error) {
                console.log(error);
            }
        },

        async save() {
            // console.log('FEE IMPORT',importedFee);
            if (!this.fee_name || !this.program || !this.fee_desc) {
                return Swal.fire({
                    icon: 'info',
                    text: 'Make sure you entered all fields properly',
                    title: 'Oops..'
                });
            }

            if (this.importFee && this.feeImport === '') {
                return Swal.fire({
                    icon: 'info',
                    text: 'Please select fee to import or you uncheck the import fee checkbox',
                    title: 'Oops..'
                })
            }
            // this.$router.push('/feeSetUp1')
            try {
                let importedFee;
                if(this.feeCollections){
                   importedFee = this.feeCollections.find(fee => fee.name === this.feeImport);
                }
                console.log('Program', this.program);
                const data = {
                    name: this.fee_name,
                    program: this.program.val,
                    email: 'skolarsuite@gmail.com',
                    session: this.session,
                    description: this.fee_desc,
                    importFee: importedFee ? importedFee.id : null
                }

                const res = await axios.post('http://localhost:5000/create-fee', data);
                // console.log('RESo', res);

                if (res.data.code === 's200') {
                    
                    const resdata = res.data.message;

                    // this.fetchFees()
                    const item = {
                       ctitle: resdata.name,
                       slug: `${this.session}_${this.fee_name}`,
                            id: resdata._id,
                            fee_id: resdata.feeId,
                            session: resdata.session,
                            programId: this.program._id,
                            program: this.program.name,
                            desc: resdata.description,
                            stat: resdata.status === 1 ? 'Active' : 'Not Active',
                            date: resdata.dateCreated.split('T')[0],
                    }
                  
                    const data = window.btoa(JSON.stringify(item));
                        this.$router.push(`/feesettings/${data}`);
                    // this.desserts.push({
                    //     ctitle: data.name,
                    //     program: this.program.name,
                    //     desc: data.description,
                    //     stat: data.status === 1 ? 'Active' : 'Not Active',
                    //     date: data.dateCreated.split('T')[0],
                    // });
                    // this.feeNames.push(this.fee_name);

                    this.program = null;
                    this.fee_desc = null;
                    this.fee_name = null;

                    this.dialog = false;

                    // this.dess

                    return Swal.fire({
                        icon: 'success',
                        text: 'Fee created successfully',
                        title: 'Successful'
                    })

                } else {
                    return Swal.fire({
                        icon: 'error',
                        text: res.data.message,
                        title: 'Oops..'
                    })
                }
            } catch (error) {
                console.log(error);
            }

            this.close()
        },
    },
}
</script>

<style lang="scss" scoped>

</style>
